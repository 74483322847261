import ENV_VARIABLES from '../config';
import { Analytics, getAnalytics } from 'firebase/analytics';
import {
  Auth,
  AuthCredential,
  User,
  confirmPasswordReset,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updatePassword
} from 'firebase/auth';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  getFirestore,
  orderBy,
  query,
  QueryConstraint,
  where
} from 'firebase/firestore';
import { CustomDocumentDataType, DocumentDataType } from '../Utils/types';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import {
  FirebaseStorage,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
  UploadResult
} from 'firebase/storage';
import { Functions, getFunctions } from 'firebase/functions';
import { generateFileName } from '../Utils/HelperFunctions/helperFunctions';

class Firebase {
  private firebaseConfig = {
    apiKey: ENV_VARIABLES.apiKey,
    authDomain: ENV_VARIABLES.authDomain,
    projectId: ENV_VARIABLES.projectId,
    storageBucket: ENV_VARIABLES.storageBucket,
    messagingSenderId: ENV_VARIABLES.messagingSenderId,
    appId: ENV_VARIABLES.appId,
    measurementId: ENV_VARIABLES.measurementId
  };
  public app: FirebaseApp;
  public analytics: Analytics;
  public storage: FirebaseStorage;
  public auth: Auth;
  public firestore: Firestore;
  public functions: Functions;
  //public appCheck: AppCheck;

  public mediaCollectionRef: CollectionReference<DocumentDataType>;

  updateMediaCollectionRef = (organisationId: string) => {
    this.mediaCollectionRef = collection(
      this.firestore,
      'organisations',
      organisationId,
      'media'
    ) as CollectionReference<DocumentDataType>;
  };

  constructor() {
    this.app = initializeApp(this.firebaseConfig);
    this.analytics = getAnalytics(this.app);
    this.storage = getStorage(this.app);
    //connectStorageEmulator(this.storage, 'localhost', 9199);
    this.auth = getAuth();
    //connectAuthEmulator(this.auth, 'http://localhost:9099');
    this.firestore = getFirestore();
    //connectFirestoreEmulator(this.firestore, 'localhost', 8080);
    this.functions = getFunctions(getApp());
    //connectFunctionsEmulator(this.functions, 'localhost', 5001);
    // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
    // key is the counterpart to the secret key you set in the Firebase console.
    /*     this.appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaV3Provider(ENV_VARIABLES.reCAPTCHASiteKey!),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    }); */

    // TODO: change so we do not have a default ref?
    this.mediaCollectionRef = collection(this.firestore, 'UNDEFINED') as CollectionReference<DocumentDataType>;
  }

  // TODO: Consider removing fully or moving fetch code from GlobalState here
  // fetchAllData = async (): Promise<CustomDocumentDataType[]> => {
  //   const q = query(this.mediaCollectionRef, orderBy('timestamp', 'desc'));
  //   const querySnapshot = await getDocs(q);
  //   const result: CustomDocumentDataType[] = querySnapshot.docs.map((doc) => ({
  //     docId: doc.id,
  //     docRef: doc.ref,
  //     ...doc.data()
  //   }));
  //   return result;
  // };

  fetchAllDataByDate = async (
    start: Date | number | string | null,
    end: Date | number | string | null
  ): Promise<CustomDocumentDataType[]> => {
    const constraints: QueryConstraint[] = [];
    if (start) constraints.push(where('timestamp', '>=', start));
    if (end) constraints.push(where('timestamp', '<=', end));
    constraints.push(orderBy('timestamp', 'desc'));
    const q = query(this.mediaCollectionRef, ...constraints);
    const querySnapshot = await getDocs(q);
    const result: CustomDocumentDataType[] = querySnapshot.docs.map((doc) => ({
      docId: doc.id,
      docRef: doc.ref,
      ...doc.data()
    }));
    return result;
  };

  downloadFile = async (fileName: string, folder: string, extension?: string): Promise<void> => {
    const fileRef = ref(this.storage, `gs://${ENV_VARIABLES.storageBucket}/${folder}/${fileName}`);
    const mataData = await getMetadata(fileRef);
    console.log('fileMetaData:', mataData);
    const downloadUrl = await getDownloadURL(
      ref(this.storage, `gs://${ENV_VARIABLES.storageBucket}/${folder}/${fileName}`)
    );
    console.log('downloadUrl:', downloadUrl);

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = generateFileName(fileName, extension);
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    };
    xhr.open('GET', downloadUrl);
    xhr.send();
  };

  deleteDocument = async (organisationId: string, docId: string): Promise<void> => {
    await deleteDoc(doc(this.firestore, 'organisations', organisationId, 'media', docId));
    // The referenced file in Storage will be deleted by a firebase cloud function
  };

  uploadFile = async (file: File, folder: string): Promise<UploadResult> => {
    const storageRef = ref(this.storage, `${folder}/${file.name}`);
    return await uploadBytes(storageRef, file);
  };

  // createDocument = async (data: DocumentDataType): Promise<DocumentReference<DocumentDataType>> => {
  //   return await addDoc(this.mediaCollectionRef, data);
  // };

  signInWithEmailAndPassword = async (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  confirmPasswordReset = async (oobCode: string, password: string) => {
    return confirmPasswordReset(this.auth, oobCode, password);
  };

  updatePassword = async (user: User, newPassword: string) => {
    return updatePassword(user, newPassword);
  };

  reauthenticateWithCredential = async (user: User, credential: AuthCredential) => {
    return reauthenticateWithCredential(user, credential);
  };
}

export const FirebaseService = new Firebase();
