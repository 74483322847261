import React from 'react';
import {
  AuthenticatedUserDataType,
  CustomDocumentDataType,
  CustomOrganisationDataType,
  CustomProjectDataType,
  CustomTokenResponseType,
  CustomUserDataType,
  DataListViewMode,
  UserRole
} from '../Utils/types';

import { onSnapshot, Unsubscribe as FireStoreUnsubscribe } from 'firebase/firestore';
import { Unsubscribe as AuthUnsubscribe, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { FirebaseService } from '../Firebase/Firebase';
import { getAllProjects, getAllUsers, getDefaultOrganisation, getOrganisation, getUser } from '../Firebase/http';

const firebaseService = FirebaseService;

type ContextType = {
  user: AuthenticatedUserDataType | undefined;
  setUser: React.Dispatch<React.SetStateAction<AuthenticatedUserDataType | undefined>>;
  organisation: CustomOrganisationDataType | undefined;
  setOrganisation: React.Dispatch<React.SetStateAction<CustomOrganisationDataType | undefined>>;
  login: (data: CustomTokenResponseType) => Promise<void>;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  data: CustomDocumentDataType[];
  dataLoading: boolean;
  firebaseService: typeof FirebaseService;
  filteredData: CustomDocumentDataType[] | null;
  setFilteredData: React.Dispatch<React.SetStateAction<CustomDocumentDataType[] | null>>;
  projects: CustomProjectDataType[];
  organisationUsers: CustomUserDataType[];
  dataListMode: DataListViewMode;
  setDataListMode: React.Dispatch<React.SetStateAction<DataListViewMode>>;
  loginAttemptMessage: string;
  setLoginAttemptMessage: React.Dispatch<React.SetStateAction<string>>;
};
const initialCTX: ContextType = {
  user: undefined,
  setUser: () => {},
  organisation: undefined,
  setOrganisation: () => {},
  login: async () => {},
  loginWithEmailAndPassword: async () => {},
  logout: async () => {},
  loading: true,
  data: [],
  dataLoading: true,
  firebaseService,
  filteredData: [],
  setFilteredData: () => {},
  projects: [],
  organisationUsers: [],
  dataListMode: 'FILES',
  setDataListMode: () => {},
  loginAttemptMessage: '',
  setLoginAttemptMessage: () => {}
};
const GlobalStateContext = React.createContext<ContextType>(initialCTX);

const GlobalState: React.FC<{ children: any }> = (props) => {
  const [user, setUser] = React.useState<AuthenticatedUserDataType>();
  const [organisation, setOrganisation] = React.useState<CustomOrganisationDataType>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<CustomDocumentDataType[]>([]);
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [filteredData, setFilteredData] = React.useState<CustomDocumentDataType[] | null>(null);
  const [projects, setProjects] = React.useState<CustomProjectDataType[]>([]);
  const [organisationUsers, setOrganisationUsers] = React.useState<CustomUserDataType[]>([]);
  const [dataListMode, setDataListMode] = React.useState<DataListViewMode>(
    (localStorage.getItem('dataListMode') as DataListViewMode) ?? 'FILES'
  );
  const [loginAttemptMessage, setLoginAttemptMessage] = React.useState<string>('');

  React.useEffect(() => {
    let authUnsubscribe: AuthUnsubscribe;
    authUnsubscribe = onAuthStateChanged(firebaseService.auth, async (u) => {
      if (u) {
        try {
          const userId = u.uid;
          const _userData = await getUser(userId);
          setUser({ credentials: u, ..._userData });
          let _orgData: CustomOrganisationDataType;
          if (!_userData.organisationId) {
            _orgData = await getDefaultOrganisation();
          } else {
            _orgData = await getOrganisation(_userData.organisationId);
          }
          setOrganisation({ ..._orgData });
          firebaseService.updateMediaCollectionRef(_orgData.docId);
        } catch (e) {
          console.log('GlobalState onAuthStateChanged failed:', e);
        }
      } else {
        // User is signed out
        setUser(undefined);
      }
      setLoading(false);
    });

    return () => {
      if (authUnsubscribe) authUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const _dataListMode = localStorage.getItem('dataListMode');
    if (_dataListMode !== dataListMode) localStorage.setItem('dataListMode', dataListMode);
  }, [dataListMode]);

  React.useEffect(() => {
    let mediaUnsubscribe: FireStoreUnsubscribe;
    setDataLoading(true);

    // TODO: Consider moving the data/collection fetching fully to Firebase.ts to improve consistency in the architecture OR remove/rename Firebase.ts

    if (user && organisation) {
      mediaUnsubscribe = onSnapshot(firebaseService.mediaCollectionRef, (snap) => {
        setData(
          snap.docs
            .map(
              (doc) =>
                ({
                  docId: doc.id,
                  docRef: doc.ref,
                  ...doc.data()
                }) as CustomDocumentDataType
            )
            .sort((a, b) => b.timestamp.toString().localeCompare(a.timestamp.toString()))
        );
        setDataLoading(false);
      });

      // Must fetch the projects by organisationId. The GlobalState projects should contain only the projects of the current organisation. As the files which are rendered on map view are only from current organisation.
      // The hook dependency will retrigger on the change of user/organisation
      getAllProjects({ params: { organisationId: organisation.docId } })
        .then((projects) => setProjects(projects))
        .catch((e) => console.log('fetching all projects failed:', e));
      if (user.role === UserRole.ADMIN || user.role === UserRole.SUPERADMIN)
        getAllUsers({ params: { organisationId: organisation.docId } })
          .then((users) => setOrganisationUsers(users))
          .catch((e) => console.log('fetching organisation users failed:', e));
    } else {
      // reset data on logout
      setData([]);
      setProjects([]);
      setFilteredData(null);
      setDataLoading(false);
    }

    return () => {
      if (mediaUnsubscribe) mediaUnsubscribe();
    };
  }, [organisation, user]);

  const login = async (res: CustomTokenResponseType) => {
    try {
      const userCredential = await signInWithCustomToken(firebaseService.auth, res.token);
      if (userCredential) setUser({ credentials: userCredential.user, ...res.user });
      firebaseService.updateMediaCollectionRef(res.org.docId);
      setOrganisation(res.org);
    } catch (e) {
      console.log('GlobalState login failed', e);
    }
  };

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const userCredential = await firebaseService.signInWithEmailAndPassword(email, password);
      const customUserCredential = await getUser(userCredential.user.uid);
      const customOrganisation = await getOrganisation(customUserCredential.organisationId);

      if (customUserCredential)
        setUser({
          credentials: userCredential.user,
          ...customUserCredential
        });

      if (customOrganisation) {
        firebaseService.updateMediaCollectionRef(customOrganisation.docId);
        setOrganisation(customOrganisation);
      }
      setLoginAttemptMessage('');
    } catch (e: any) {
      if (e.code === 'auth/wrong-password') {
        setLoginAttemptMessage('Wrong password!');
      }
      if (e.code === 'auth/user-not-found') {
        setLoginAttemptMessage('User not found!');
      }
      console.error('Email and password login failed', e);
    }
  };

  const logout = async () => {
    try {
      await signOut(firebaseService.auth);
      setData([]);
      setProjects([]);
      setFilteredData(null);
      setUser(undefined);
      setOrganisation(undefined);
    } catch (error) {
      toast.error('Failed to sign out!');
      console.log(error);
    }
  };

  return (
    <GlobalStateContext.Provider
      value={{
        user,
        setUser,
        organisation,
        setOrganisation,
        login,
        loginWithEmailAndPassword,
        logout,
        loading,
        data,
        dataLoading,
        firebaseService,
        filteredData,
        setFilteredData,
        projects,
        organisationUsers,
        dataListMode,
        setDataListMode,
        loginAttemptMessage,
        setLoginAttemptMessage
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalState;

export const useGlobalState = () => React.useContext(GlobalStateContext);
