import newFrozenEyeLogo from '../Utils/img/newFrozenEyeLogo.png';
import copyToClipboard from 'copy-to-clipboard';
import Offcanvas from 'react-bootstrap/Offcanvas';
import QRCode from 'react-qr-code';
import React from 'react';
import styles from './AppShell.module.scss';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Spinner
} from 'reactstrap';
import {
  faBars,
  faCamera,
  faCopy,
  faFileShield,
  faGear,
  faPrint,
  faSignOut,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCameraLink } from '../Utils/HelperFunctions/helperFunctions';
import { toast } from 'react-toastify';
import { useGlobalState } from '../GlobalState/GlobalState';
import { useReactToPrint } from 'react-to-print';
import { UserRole } from '../Utils/types';
import { useIsMobileView } from '../Utils/HelperFunctions/Hooks';
import { generateCameraLink } from '../Firebase/http';

export const AppShell: React.FC<{ children: any }> = (props) => {
  const globalState = useGlobalState();
  const [navbarVisible, setNavbarVisible] = React.useState<boolean>(false);
  const [showGeneratedLinkModal, setShowGeneratedLinkModal] = React.useState<boolean>(false);
  const [isLoadingGeneratedLink, setIsLoadingGeneratedLink] = React.useState<boolean>(false);
  const [tempGeneratedLinkId, setTempGeneratedLinkId] = React.useState<string>('');
  const [permaGeneratedLinkId, setPermaGeneratedLinkId] = React.useState<string>('');
  const isMobileView = useIsMobileView();

  const orgLogo = globalState.organisation?.organisationLogo;

  const onClickGenerateLink = async () => {
    setIsLoadingGeneratedLink(true);
    try {
      const tempLinkData = await generateCameraLink(globalState.organisation?.docId || '');
      const permaLinkData = await generateCameraLink(globalState.organisation?.docId || '', true);

      setNavbarVisible(false);
      setTempGeneratedLinkId(getCameraLink(tempLinkData.fId));
      setPermaGeneratedLinkId(getCameraLink(permaLinkData.fId));
      setShowGeneratedLinkModal(true);
    } catch (error) {
      console.log('Error generating link:', error);
      toast.error('Something went wrong!', { theme: 'light' });
    } finally {
      setIsLoadingGeneratedLink(false);
    }
  };

  return (
    <div className={styles.shellContainer}>
      <Navbar
        style={{
          backgroundColor: globalState.organisation?.organisationColor ?? 'primary'
        }}
        dark
        className={styles.navbar}
      >
        <NavbarBrand href='/' className='fw-bold d-flex gap-2 align-items-center text-white'>
          <img src={orgLogo ?? newFrozenEyeLogo} alt='brand' style={{ height: '2em' }}></img>
          <span>{globalState.organisation?.displayName ?? 'The Frozen Eye'}</span>
        </NavbarBrand>
        {isMobileView ? (
          <>
            <Button
              onClick={() => setNavbarVisible(!navbarVisible)}
              outline
              color='light'
              style={{ borderColor: 'transparent' }}
            >
              <FontAwesomeIcon icon={faBars} size='xl' />
            </Button>
          </>
        ) : (
          <>
            <NavItems onClickGenerateLink={onClickGenerateLink} isLoadingGeneratedLink={isLoadingGeneratedLink} />
          </>
        )}
      </Navbar>

      <Offcanvas
        show={navbarVisible}
        onHide={() => setNavbarVisible(false)}
        placement='end'
        className={styles.offcanvas}
      >
        <Offcanvas.Header closeButton closeVariant='white' style={{ borderBottom: '1px solid rgba(255,255,255,0.2)' }}>
          <Offcanvas.Title>
            <a href='/' className='fw-bold text-light text-decoration-none d-flex gap-2 align-items-center'>
              <img src={newFrozenEyeLogo} alt='brand' style={{ height: '2em' }}></img>
              <span>
                <div>The Frozen Eye</div>
                {globalState.user?.role !== UserRole.SUPERADMIN && (
                  <span
                    className='fw-lighter fst-italic fs-6 d-inline-block text-truncate'
                    style={{ maxWidth: '15rem' }}
                  >
                    {globalState.organisation?.displayName || ''}
                  </span>
                )}
              </span>
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Navbar dark>
            <NavItems
              vertical
              onClickGenerateLink={onClickGenerateLink}
              isLoadingGeneratedLink={isLoadingGeneratedLink}
            />
          </Navbar>
        </Offcanvas.Body>
      </Offcanvas>

      <GeneratedLinkModal
        isOpen={showGeneratedLinkModal}
        setIsOpen={setShowGeneratedLinkModal}
        tempLink={tempGeneratedLinkId}
        permaLink={permaGeneratedLinkId}
      />

      <div className={`${styles.mainContainer} bg-light`}>{props.children}</div>
    </div>
  );
};

type NavItemsProps = {
  isLoadingGeneratedLink: boolean;
  vertical?: boolean;
  onClickGenerateLink: () => void;
};
const NavItems = ({ vertical, onClickGenerateLink, isLoadingGeneratedLink }: NavItemsProps) => {
  const globalState = useGlobalState();

  return (
    <>
      <Nav navbar className={`ml-auto me-0 gap-2 ${vertical ? 'w-100' : 'flex-row'}`}>
        {(globalState.user?.role === UserRole.SUPERADMIN || globalState.user?.role === UserRole.ADMIN) && (
          <NavItem>
            <NavBtn icon={faCamera} onClick={onClickGenerateLink} wide={vertical} loading={isLoadingGeneratedLink}>
              Generate camera link
            </NavBtn>
          </NavItem>
        )}
        <NavItem>
          <NavLink href={'/settings'} className='p-0'>
            <NavBtn icon={faGear} wide={vertical}>
              Settings
            </NavBtn>
          </NavLink>
        </NavItem>
        {(globalState.user?.role === UserRole.SUPERADMIN || globalState.user?.role === UserRole.ADMIN) && (
          <NavItem>
            <NavLink href={'/projects'} className='p-0'>
              <NavBtn icon={faFileShield} wide={vertical}>
                Projects
              </NavBtn>
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavBtn
            icon={faSignOut}
            onClick={async () => {
              await globalState.logout();
            }}
            wide={vertical}
          >
            Logout
          </NavBtn>
        </NavItem>
      </Nav>
    </>
  );
};

type NavBtnProps = {
  children?: JSX.Element | string;
  icon: IconDefinition;
  wide?: boolean;
  loading?: boolean;
  [key: string]: any;
};
const NavBtn = ({ children, icon, wide, loading, ...props }: NavBtnProps) => {
  return (
    <Button
      outline
      color='light'
      size='sm'
      className={`${styles.navbtn} fw-bold d-flex align-items-center ${wide && 'w-100 py-2 fs-6'}`}
      {...props}
    >
      {loading ? <Spinner size='sm' /> : <FontAwesomeIcon icon={icon} />}
      <span className={`${wide ? 'flex-grow-1 text-start ps-4' : 'ps-1'}`}>{children}</span>
    </Button>
  );
};

type GeneratedLinkModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  tempLink: string;
  permaLink: string;
};
export const GeneratedLinkModal = ({ isOpen, setIsOpen, tempLink, permaLink }: GeneratedLinkModalProps) => {
  const qrRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => qrRef.current
  });

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>Generated a temporary camera link</ModalHeader>
      <ModalBody>
        <p>The following link will be valid for 12 hours.</p>
        <p>To access this organisation's camera after the link expires, a new link will need to be generated.</p>
        <p>
          Temporary link:{' '}
          <a href={tempLink} target='_blank' rel='noreferrer' style={{ wordBreak: 'break-word' }}>
            {tempLink}
          </a>
        </p>
        <p>
          Permanent link:{' '}
          <a href={permaLink} target='_blank' rel='noreferrer' style={{ wordBreak: 'break-word' }}>
            {permaLink}
          </a>
        </p>
      </ModalBody>
      <ModalFooter className='d-flex gap-1'>
        <div className='d-flex gap-1'>
          <Button
            color='primary'
            onClick={() => {
              copyToClipboard(tempLink);
              toast.success('Url copied to clipboard!', { theme: 'light' });
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
            &nbsp; Copy temporary link
          </Button>
          <Button
            color='primary'
            onClick={() => {
              copyToClipboard(permaLink);
              toast.success('Url copied to clipboard!', { theme: 'light' });
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
            &nbsp; Copy permanent link
          </Button>
          <Button color='primary' onClick={handlePrint}>
            <FontAwesomeIcon icon={faPrint} />
            &nbsp; Print temporary link QR code
            {/* this qr code is only visible on the print-out */}
            <span style={{ overflow: 'hidden', width: 0, height: 0, position: 'absolute' }}>
              <div ref={qrRef}>
                <QRCode
                  value={tempLink}
                  style={{ position: 'relative', left: 'calc(50% - 128px)', top: 'calc(50vh - 128px)' }}
                />
              </div>
            </span>
          </Button>
          <Button color='primary' onClick={handlePrint}>
            <FontAwesomeIcon icon={faPrint} />
            &nbsp; Print permanent link QR code
            {/* this qr code is only visible on the print-out */}
            <span style={{ overflow: 'hidden', width: 0, height: 0, position: 'absolute' }}>
              <div ref={qrRef}>
                <QRCode
                  value={permaLink}
                  style={{ position: 'relative', left: 'calc(50% - 128px)', top: 'calc(50vh - 128px)' }}
                />
              </div>
            </span>
          </Button>
        </div>
        <Button
          color='danger'
          className='ms-auto'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
