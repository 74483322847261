import newFrozenEyeLogo from '../../Utils/img/newFrozenEyeLogo.png';
import NotFoundPlaceHolder from '../../Utils/img/NotFoundPlaceHolder.png';
import ReactPlayer from 'react-player';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, Spinner } from 'reactstrap';
import {
  downloadAsPDF,
  downloadPDFReport,
  getAllStatuses,
  getFilePublicUrl,
  getOrganisationByUrlId
} from '../../Firebase/http';
import { downloadURI } from '../../Utils/HelperFunctions/helperFunctions';
import { CustomStatusDataType, FileTypes, HistoryItem } from '../../Utils/types';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrafficLight, faUserTag } from '@fortawesome/free-solid-svg-icons';

const DownloadPage = () => {
  const globalState = useGlobalState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState<string>();
  const params = useParams() as { urlId: string; filetype: FileTypes; filename: string; token: string };
  const [pdfReportLoading, setPdfReportLoading] = useState<boolean>(false);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState<boolean>(false);
  const [orgId, setOrgId] = useState<string>('');
  const [fileHistory, setFileHistory] = useState<HistoryItem[]>([]);
  const [statuses, setStatuses] = useState<CustomStatusDataType[]>([]);

  useEffect(() => {
    getOrganisationByUrlId(params.urlId)
      .then((org) => {
        setOrgId(org.docId);
        getFilePublicUrl(org.docId, params.filename)
          .then((publicUrl) => {
            setFileUrl(publicUrl);
            setLoading(false);
            setError(false);
          })
          .catch((error) => {
            setError(true);
            setLoading(false);
          });
        setFileHistory(globalState.data.find((d) => d.fileName === params.filename)?.history ?? []);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, [params.filename, params.urlId, globalState.data]);

  useEffect(() => {
    const fetchStatuses = async () => {
      if (orgId == null) {
        setStatuses([]);
        return;
      }
      setStatuses(await getAllStatuses({ params: { organisationId: orgId } }));
    };

    fetchStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  document.title = globalState.organisation?.displayName ?? 'The Frozen Eye';

  const assigneeEntry = (item: HistoryItem) => {
    const user = globalState.organisationUsers.find((u) => u.docId === item.after);
    return (
      <p>
        <FontAwesomeIcon icon={faUserTag} style={{ marginRight: '4px', width: '20px' }} size='1x' />
        Assignee changed to {user?.displayName ? <b>{user.displayName}</b> : <i>Nobody</i>}
      </p>
    );
  };

  const statusIdEntry = (item: HistoryItem) => {
    const status = statuses.find((s) => s.docId === item.after);
    return (
      <>
        <p>
          <FontAwesomeIcon icon={faTrafficLight} style={{ marginRight: '4px', width: '20px' }} size='1x' />
          Status changed to <b>{status?.text}</b>
        </p>
      </>
    );
  };

  const adminMessageEntry = (item: HistoryItem) => {
    const user = globalState.organisationUsers.find((u) => u.docId === item.userId);
    const date = item.timestamp.toDate();
    return (
      <div>
        <small>
          <b>{user?.displayName ?? '<UNKNOWN>'}</b> wrote at {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </small>
        <p>{item.after}</p>
      </div>
    );
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      {!globalState.user && (
        <Navbar color='primary' expand='sm' dark sticky='top' style={{ height: '3em', width: '100%' }}>
          <NavbarBrand>
            <img src={newFrozenEyeLogo} alt='brand' width={35} style={{ marginRight: 5 }}></img>
            Download | The Frozen Eye
          </NavbarBrand>
        </Navbar>
      )}
      <div className=' m-3'>
        <div>
          {loading && (
            <div className='d-flex flex-column justify-content-center align-items-center pt-2 '>
              <Spinner style={{ width: '2em', height: '2em' }}></Spinner>
              <p>Loading...</p>
            </div>
          )}
          {!error ? (
            params.filetype === FileTypes.IMAGE ? (
              <img
                src={fileUrl}
                alt='/'
                style={{ width: '25rem', maxWidth: '25rem', maxHeight: '35rem', marginBottom: '1rem' }}
                onLoad={() => {
                  setLoading(false);
                }}
                onError={(e) => {
                  console.log('image loading error', e);
                  setError(true);
                }}
              />
            ) : (
              <ReactPlayer
                width={'25rem'}
                height={'100%'}
                controls={true}
                url={fileUrl}
                onError={(e) => {
                  console.log('video loading error', e);
                  setError(true);
                }}
                onReady={() => {
                  setLoading(false);
                }}
              ></ReactPlayer>
            )
          ) : (
            <img
              alt='error'
              src={NotFoundPlaceHolder}
              style={{ width: '25rem', maxWidth: '25rem', maxHeight: '35rem', marginBottom: '1rem' }}
            />
          )}
        </div>
        {params.filetype === FileTypes.IMAGE ? (
          <div className='d-flex gap-1'>
            <Button
              onClick={async () => {
                setPdfReportLoading(true);
                await downloadPDFReport(orgId, params.filename);
                setPdfReportLoading(false);
              }}
              style={{ width: '200px' }}
              color='primary'
              disabled={!fileUrl || loading || error || pdfReportLoading}
            >
              {pdfReportLoading ? <Spinner size='sm'></Spinner> : 'Download pdf report'}
            </Button>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              direction={'down'}
              color='primary'
              disabled={!fileUrl || loading || error || pdfDownloadLoading}
            >
              <DropdownToggle caret color='primary' disabled={!fileUrl || pdfDownloadLoading || loading || error}>
                {pdfDownloadLoading ? (
                  <span style={{ width: '110px', display: 'inline-block' }}>
                    <Spinner size='sm'></Spinner>
                  </span>
                ) : (
                  'Download file'
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Download as...</DropdownItem>
                <DropdownItem onClick={() => downloadURI(fileUrl!, params.filename, 'png')}>PNG</DropdownItem>
                <DropdownItem onClick={() => downloadURI(fileUrl!, params.filename, 'jpeg')}>JPEG</DropdownItem>
                <DropdownItem
                  onClick={async () => {
                    setPdfDownloadLoading(true);
                    await downloadAsPDF(orgId, params.filename);
                    setPdfDownloadLoading(false);
                  }}
                >
                  PDF
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : (
          <Button
            color='primary'
            onClick={() => downloadURI(fileUrl!, params.filename)}
            disabled={!fileUrl || loading || error}
          >
            Download video
          </Button>
        )}
        <div>
          <h3 style={{ marginTop: '12px' }}>History</h3>
          <>
            {fileHistory
              .sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis())
              .map((h) => {
                switch (h.field) {
                  case 'assignee':
                    return assigneeEntry(h);
                  case 'statusId':
                    return statusIdEntry(h);
                  case 'adminMessage':
                    return adminMessageEntry(h);
                  default:
                    return <></>;
                }
              })}
          </>
        </div>
      </div>
    </>
  );
};
export default DownloadPage;

/**
 * https://00f74ba44bfe4bb5451c7a427552d44231d4bceccd-apidata.googleusercontent.com/download/storage/v1/b/trygghetsmobilen-sweden.appspot.com/o/images%2F0D156832-DC39-42D1-A9B1-3C4FE5832F1F.jpeg?jk=AFshE3WfDjbNVv5VQmpRhwP97bn_bby_ESFY8I444-Aw5V_XJOjE0OMTqeOhpuQ10MqnlmIwr5Tdq8dN3czyk8Wk4ExYvLpLS8aepBDAucic2tlEVKkGPfbQImt2PdOEhxpBcKIAk4l7j0FysxrGwifqleBg8Nb2cyU7lOIuPz2G1WZBNI6wLO_UlHJRnTnCu2KXoJgSLDiMac6gbEY6f--WKupYf-AM5myV0alANcpjBhhoGw9zmTor-fvyGMdHTRxNMSRz4Os9LVQC62E7OgjO1Pze8vH78vCbnjhIwkgdCi9AoFbr1XRPDEiCAM3YSXkFIkkwoLtBNnbWqphMjnWoAVKlP0I0ENEcL3QU-8SwzlXtl0LAeiY7GoPmUy6DAvQPXq-lp945alsDZ8Tt_CwcIRxL2tw7B8N8VYHz_aGh2zlmyPh3PqSFUDJpo__a75-iDIi9gmhE4df1tJVxnWk7QWMsjvV_fW41KyfZhGmQrFyLaDt2qlzaGl3Ym1xj61Llig_SlZQ5kMXEXhlr4dViXe2Qit8AhVmN-E-ulxan_inlEXwFOZa8gIV78LiZZrnYAY21DEv729CREUJWJssBX3aUhHLpZAagMBTGHwaHqQbrZCpgA2cLEQXVFPu-H0WVzREIWMmbf6pWZTT-nmaxIOcpHbQzvs1sDLC2iFbQvrQO_o2cBSi-WeseW1onBrPm9mn2zEvIlb-r_tsNPud-7KArFwGNr6DiO_7SMM5Hnl7YJYOdp4qh9qpVe4wumYBHROqu4qmdTmj6fiNBfl3zLXzKHqSJdVWBJ8oHabQQDoXeNtBnYBmIEz3mnOZIccYZmz6M1EuTVkhwT1BK7nre-y2xK2dCJHh_t7I1C2LUGATauOUwH1FIMTNGddCRoOTvLQcgfkSYK1C3fbue84LOB-OtSoG8UTs-YRl7UfgIx78nEURbvFNE3x8mZCGHt10jfgPODGpxcUErr333dqAYgI6juJjM1Ne-J37P_jxwRkQTOJjT_1S1MEwqr6_IIboMdkU4j-mdwLFCPpzvshrfqQ5cezZI6wEUkzIcOmdP-QkvBmmYBffX3CGJD2JpkMWz7uUvVvzlzJblHMTgh87x043WapXQ-tJvGmdhm3vDpNMic6cvu0HEirI-EjXQc_k7NgtE7FrQ7zOdfEY3S6HL7WZkcGNhCGjB2xNVl4Ag4E9stJ-pxF1bmBgYNaCvmWt3iZdS7xg9sWOAKp3AkuS9xdLKv_-GdWqyl0PABiT1Cbt5NKp43JhRDCBDPR3VExETgnE51_xxfYTDyo8&isca=1
 */
