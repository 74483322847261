import { faFileExport, faFileImport, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { RoundedIconButton } from '../../Components/Buttons';
import { SortableTable } from '../../Components/SortableTable';
import { OrganisationType, WithFID, StatusDataType } from '../../Utils/types';
import { BlockModule } from './BlockModule';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import styles from './SystemAdminPage.module.scss';
import { createStatus, deleteStatus, editStatus } from '../../Firebase/http';
import { FirebaseService } from '../../Firebase/Firebase';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useCSVReader, usePapaParse } from 'react-papaparse';
import { toast } from 'react-toastify';

const firebaseService = FirebaseService;

type StatusModuleProps = {
  organisation: OrganisationType & WithFID;
};

// TODO: There might become thousands of statuses. Do we need pagination?

export const StatusBlock = ({ organisation }: StatusModuleProps) => {
  const [showModalCreate, setShowModalCreate] = React.useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);
  const [statuses, setStatuses] = React.useState<(StatusDataType & WithFID)[]>([]);
  const [statusToModify, setStatusToModify] = React.useState<StatusDataType & WithFID>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const globalState = useGlobalState();
  const { CSVReader } = useCSVReader();
  const { jsonToCSV } = usePapaParse();
  const organisationColor = globalState.organisation?.organisationColor;

  React.useEffect(() => {
    const unsubscribeStatusesCollection = onSnapshot(
      query(collection(firebaseService.firestore, `organisations/${globalState.user?.organisationId}/statuses`)),
      (snap) => {
        setStatuses(snap.docs.map((d: any) => ({ ...(d.data() as StatusDataType), fId: d.id })));
        setIsLoading(false);
      },
      (err) => {
        setStatuses([]);
        setIsLoading(false);
      }
    );
    return () => {
      unsubscribeStatusesCollection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const importCsv = (results: { data: Array<unknown>; errors: Array<unknown>; meta: Array<unknown> }) => {
    let unsuccessfulImports = [];

    for (let i = 0; i < results.data.length; i++) {
      const statusObject = results.data[i] as Object;

      if ('name' in statusObject && 'text' in statusObject && 'index' in statusObject) {
        // TODO: Check all kinds of CSV formatting problems that might arise.
        // TODO: Check for duplicates within CSV.
        // TODO: Check that indices are not already used.
        if (
          statuses.some((s) => {
            // eslint-disable-next-line eqeqeq
            return s.name == statusObject.name && s.text == statusObject.text;
          })
        ) {
          continue;
        }
        try {
          createStatus({
            name: (statusObject.name as string).trim(),
            text: (statusObject.text as string).trim(),
            index: parseInt(statusObject.index as string)
          });
        } catch (error) {
          unsuccessfulImports.push(i);
        }
      } else {
        toast.error('CSV is incorrectly formatted');
        return;
      }
    }
    if (unsuccessfulImports.length === 0) {
      toast.success('Import successful');
    } else if (unsuccessfulImports.length === results.data.length) {
      toast.error('CSV failed to import');
    } else {
      const warning = `Import partially successful, but line${
        unsuccessfulImports.length === 1 ? '' : 's'
      } ${unsuccessfulImports.map((e) => e + 1)} failed to import.`;
      toast.warning(warning);
      console.warn(warning);
    }
  };

  const exportCsv = () => {
    const csvString = jsonToCSV(
      statuses.map((s): StatusDataType => {
        return { name: s.name, text: s.text, index: s.index };
      })
    );
    const csvBlob = new Blob([csvString], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const csvLink = document.createElement('a');
    csvLink.download = 'statuses.csv';
    csvLink.href = csvUrl;
    csvLink.click();
    URL.revokeObjectURL(csvLink.href);
  };

  return (
    <>
      <CSVReader onUploadAccepted={importCsv} config={{ header: true, skipEmptyLines: true }}>
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
          <BlockModule
            title={
              <div className='d-flex mb-2 gap-2'>
                <h2 className='ms-3'>Statuses</h2>
                <RoundedIconButton
                  icon={faPlus}
                  onClick={() => setShowModalCreate(true)}
                  tooltip='Add status'
                  tooltipPosition='top'
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                />
                <RoundedIconButton
                  icon={faFileImport}
                  {...getRootProps()}
                  tooltip='Import CSV'
                  tooltipPosition='top'
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                />
                <RoundedIconButton
                  icon={faFileExport}
                  onClick={exportCsv}
                  tooltip='Export CSV'
                  tooltipPosition='top'
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                />
              </div>
            }
          >
            {!isLoading ? (
              <div>
                <SortableTable
                  data={statuses}
                  columns={[
                    { label: 'Name', key: 'name' },
                    { label: 'Text', key: 'text' },
                    { label: 'Order Index', key: 'index' }
                  ]}
                  defaultSortColumn='name'
                  actions={{
                    actions: {
                      delete: (id) => {
                        setStatusToModify(statuses.find((status) => status.fId === id));
                        setShowModalDelete(true);
                      },
                      edit: (id) => {
                        setStatusToModify(statuses.find((status) => status.fId === id));
                        setShowModalEdit(true);
                      }
                    },
                    idKey: 'fId'
                  }}
                  emptyPlaceholder="No statuses exist for this organisation! Click the '+' button to add one!"
                ></SortableTable>
              </div>
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
              </div>
            )}
          </BlockModule>
        )}
      </CSVReader>
      <EditAndCreateModal
        organisation={organisation}
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        modalType='create'
        statusToModify={undefined}
        numberOfStatuses={statuses.length}
      />

      <EditAndCreateModal
        organisation={organisation}
        isOpen={showModalEdit}
        setIsOpen={setShowModalEdit}
        modalType='edit'
        statusToModify={statusToModify}
        numberOfStatuses={statuses.length}
      />

      <DeleteModal statusToDelete={statusToModify} isOpen={showModalDelete} setIsOpen={setShowModalDelete} />
    </>
  );
};

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  statusToDelete: (StatusDataType & WithFID) | undefined;
};
const DeleteModal = ({ isOpen, setIsOpen, statusToDelete }: DeleteModalProps) => {
  const [requestError, setRequestError] = React.useState<string>('');
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>Delete Status</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete "{statusToDelete?.name}"?</p>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!statusToDelete) return;
            setIsLoadingRequest(true);
            setRequestError('');

            try {
              await deleteStatus(statusToDelete?.fId);
              setIsOpen(false);
              setIsLoadingRequest(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error adding user: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to add user');
              }
              setIsLoadingRequest(false);
            }
          }}
          className='me-auto'
          style={{ minWidth: 80 }}
          disabled={isLoadingRequest}
        >
          {isLoadingRequest ? <Spinner size='sm' /> : 'Yes'}
        </Button>
        <Button color='danger' onClick={() => setIsOpen(false)} disabled={isLoadingRequest}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type EditAndCreateModalProps = {
  organisation: OrganisationType & WithFID;
  isOpen: boolean;
  modalType: 'edit' | 'create';
  setIsOpen: (isOpen: boolean) => void;
  statusToModify: (StatusDataType & WithFID) | undefined;
  numberOfStatuses: number;
};

const EditAndCreateModal = ({
  organisation,
  isOpen,
  modalType,
  setIsOpen,
  statusToModify,
  numberOfStatuses
}: EditAndCreateModalProps) => {
  const [name, setName] = React.useState<string>('');
  const [text, setText] = React.useState<string>('');
  const [index, setIndex] = React.useState<number>(0);

  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');

  const onSubmit = async () => {
    if (modalType === 'edit') {
      if (!statusToModify) return;
      if (statusToModify.name === name && statusToModify.text === text && statusToModify.index === index) {
        setIsOpen(false);
        return;
      }
    }

    setFormSubmitLoading(true);
    setRequestError('');
    try {
      if (modalType === 'create') {
        await createStatus({
          name,
          text,
          index
        });
      } else {
        if (!statusToModify) return;
        await editStatus({ name, text, index }, statusToModify.fId);
      }
      setIsOpen(false);
      setFormSubmitLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response?.data);
        setRequestError(
          `Error ${modalType === 'create' ? 'creating' : 'updating'} status: ${error.response?.data.error}`
        );
      } else {
        console.log(error);
        setRequestError(`Failed to ${modalType} status`);
      }
      setFormSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      if (modalType !== 'create' && statusToModify) {
        setName(statusToModify.name);
        setText(statusToModify.text);
      }
    } else {
      setRequestError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader> {modalType === 'create' ? 'Create new' : 'Edit'} status</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for='nameCreate' className={styles.label}>
              Name
            </Label>
            <Input
              onChange={(e) => setName(e.target.value)}
              name='nameCreate'
              type='text'
              placeholder='Enter a name for the status...'
              defaultValue={modalType === 'create' ? '' : statusToModify?.name}
            />
          </FormGroup>

          <FormGroup>
            <Label for='textCreate' className={styles.label}>
              Text
            </Label>
            <Input
              id='textCreate'
              name='textCreate'
              type='text'
              onChange={(e) => setText(e.target.value)}
              placeholder='Enter the displayed text for the status...'
              defaultValue={modalType === 'create' ? '' : statusToModify?.text}
            />
          </FormGroup>

          <FormGroup>
            <Label for='indexCreate' className={styles.label}>
              Sort Index
            </Label>
            <Input
              id='indexCreate'
              name='indexCreate'
              type='number'
              min={1}
              onChange={(e) => setIndex(parseInt(e.target.value))}
              defaultValue={modalType === 'create' ? numberOfStatuses + 1 : statusToModify?.index}
            />
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '8em' }}
          disabled={!organisation || formSubmitLoading}
          onClick={onSubmit}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : `${modalType === 'create' ? 'Add' : 'Update'} status`}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
