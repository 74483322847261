import { DocumentReference, GeoPoint, Timestamp } from 'firebase/firestore';
import { User as FBUser } from 'firebase/auth';

export interface DocumentDataType {
  fileName: string;
  fileType: FileTypes;
  timestamp: Timestamp;
  location: GeoPoint;
  adminMessage: string;
  message: string;
  uploadedBy: string;
  isRead: boolean;
  projectId: string;
  statusId?: string;
  assignee: string;
  history?: HistoryItem[];
}

export interface CustomDocumentDataType extends DocumentDataType {
  docId: string;
  docRef: DocumentReference;
}

export interface UserDataType {
  displayName?: string;
  organisationId: string;
  password?: string;
  role: UserRole;
  email?: string;
  status?: UserStatus;
}

export interface UserDataTypeWithFID extends UserDataType {
  fId: string;
}

export interface CustomUserDataType extends UserDataType {
  docId: string;
  docRef: DocumentReference;
}

export interface AuthenticatedUserDataType extends CustomUserDataType {
  credentials: FBUser;
}

export interface OrganisationDataType {
  urlId: string;
  displayName: string;
}

// TODO: replace more interfaces with types for convenience
export type OrganisationType = {
  urlId: string;
  displayName: string;
  organisationColor?: string;
  organisationLogo?: string;
};

export type WithFID = {
  fId: string;
};

export type WithCreationDate = {
  createdAt: Timestamp;
};

export type CameraLinkDocumentType = {
  expiry: Timestamp;
  organisationId: string;
  projectId?: string;
};

export interface CustomOrganisationDataType extends OrganisationDataType {
  docId: string;
  docRef: DocumentReference;
  organisationColor?: string;
  organisationLogo?: string;
}

export enum FileTypes {
  IMAGE = 'image',
  VIDEO = 'video'
}

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export interface CustomTokenResponseType {
  token: string;
  user: CustomUserDataType;
  org: CustomOrganisationDataType;
}

export type ProjectDataType = {
  name: string;
  organisationId: string;
  statusId?: string;
  permanentLink?: string;
};

export interface CustomProjectDataType extends ProjectDataType {
  docId: string;
  docRef: DocumentReference;
}

export type StatusDataType = {
  name: string;
  text: string;
  index: number;
};

export interface CustomStatusDataType extends StatusDataType {
  docId: string;
  docRef: DocumentReference;
}
export type DataListViewMode = 'FILES' | 'PROJECTS';

export enum UserStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive'
}
export type HistoryItem = {
  before: any;
  after: any;
  field: 'assignee' | 'statusId' | 'adminMessage';
  timestamp: Timestamp;
  userId: string;
};
