import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Nav } from 'reactstrap';
import { FirebaseService } from '../../Firebase/Firebase';
import { getBackups } from '../../Firebase/http';
import {
  OrganisationType,
  UserDataType,
  UserDataTypeWithFID,
  UserRole,
  WithCreationDate,
  WithFID
} from '../../Utils/types';
import { AccountBlock } from './AccountBlock';
import { BackupBlock } from './BackupBlock';
import { GeneralBlock } from './GeneralBlock';
import { OrganisationsBlock } from './OrganisationsBlock';
import { UserBlock } from './UserBlock';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { OrganisationBlock } from './OrganisationBlock';
import { StatusBlock } from './StatusBlock';

const firebaseService = FirebaseService;

export const SystemAdminPage: React.FC<{}> = () => {
  const { organisationId: paramOrgId } = useParams() as { organisationId: string };
  const navigate = useNavigate();
  const globalState = useGlobalState();

  const [activeOrg, setActiveOrg] = React.useState<(OrganisationType & WithCreationDate & WithFID) | undefined>(
    undefined
  );
  const [orgs, setOrgs] = React.useState<(OrganisationType & WithCreationDate & WithFID)[]>([]);
  const [users, setUsers] = React.useState<UserDataTypeWithFID[]>([]);
  const [backups, setBackups] = React.useState<string[]>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = React.useState<boolean>(true);

  document.title = 'Settings';

  React.useEffect(() => {
    getBackups().then((data) => setBackups(data));

    const unsubscribeOrgsCollection = onSnapshot(
      collection(firebaseService.firestore, 'organisations'),
      (snap) => {
        setOrgs(snap.docs.map((d) => ({ ...(d.data() as OrganisationType & WithCreationDate & WithFID), fId: d.id })));
        setIsLoadingOrgs(false);
      },
      (err) => {
        setOrgs([]);
      }
    );
    const unsubscribeUsersCollection = onSnapshot(
      collection(firebaseService.firestore, 'users'),
      (snap) => {
        setUsers(snap.docs.map((d) => ({ ...(d.data() as UserDataType), fId: d.id })));
      },
      (err) => {
        setUsers([]);
      }
    );
    return () => {
      unsubscribeOrgsCollection();
      unsubscribeUsersCollection();
    };
  }, []);

  React.useEffect(() => {
    if (isLoadingOrgs) {
      return;
    }
    if (orgs.some((o) => o.fId === paramOrgId)) {
      if (paramOrgId !== activeOrg?.fId) {
        setActiveOrg(orgs.find((o) => o.fId === paramOrgId));
      }
    } else {
      setActiveOrg(undefined);
      navigate('/settings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg, orgs, paramOrgId]);

  return (
    <div className='h-100 d-flex bg-light'>
      {activeOrg ? (
        <div className='flex-grow-1 p-4 d-flex flex-column align-items-center' style={{ overflow: 'auto' }}>
          <Nav className='mb-4 d-flex w-100' style={{ maxWidth: '55em' }}>
            <Button onClick={() => navigate('')} outline className='border-0' color={activeOrg.organisationColor}>
              <FontAwesomeIcon icon={faAngleLeft} className='me-1' />
              Back
            </Button>
            <h1 className='mb-0 ms-auto me-auto'>{orgs.find((o) => o.fId === activeOrg.fId)?.displayName}</h1>
          </Nav>

          <GeneralBlock activeOrg={activeOrg} orgs={orgs} />
          <AccountBlock activeOrg={activeOrg} users={users} />
          <BackupBlock activeOrg={activeOrg} backups={backups} />
        </div>
      ) : (
        <div className='w-100 p-4'>
          <div className='me-auto ms-auto' style={{ maxWidth: '55em' }}>
            {globalState.user != null ? <UserBlock user={globalState.user}></UserBlock> : <></>}
            {globalState.organisation != null &&
              globalState.user != null &&
              (globalState.user.role === UserRole.ADMIN || globalState.user.role === UserRole.SUPERADMIN) && (
                <OrganisationBlock
                  organisation={{ ...globalState.organisation, fId: globalState.user.organisationId }}
                ></OrganisationBlock>
              )}
            {globalState.organisation != null &&
              globalState.user != null &&
              (globalState.user.role === UserRole.ADMIN || globalState.user.role === UserRole.SUPERADMIN) && (
                <AccountBlock
                  activeOrg={{ ...globalState.organisation, fId: globalState.user.organisationId }}
                  users={users}
                ></AccountBlock>
              )}
            {globalState.organisation != null &&
              globalState.user != null &&
              (globalState.user.role === UserRole.ADMIN || globalState.user.role === UserRole.SUPERADMIN) && (
                <StatusBlock
                  organisation={{ ...globalState.organisation, fId: globalState.user.organisationId }}
                ></StatusBlock>
              )}
            {globalState.user != null && globalState.user.role === UserRole.SUPERADMIN && (
              <OrganisationsBlock orgs={orgs} hasFinishedLoading={!isLoadingOrgs} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
