import newFrozenEyeLogo from '../../Utils/img/newFrozenEyeLogo.png';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormGroup, InputGroup, Input, FormFeedback, InputGroupText, Button, Spinner } from 'reactstrap';
import styles from './SignupPage.module.scss';
import React from 'react';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUser, registerUser } from '../../Firebase/http';

type LocationState = { from: { pathname: string } };

const SignupPage: React.FC<{}> = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [displayName, setDisplayName] = React.useState<string>('');
  const [displayNameError, setDisplayNameError] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [signupLoading, setSignupLoading] = React.useState<boolean>(false);
  const from = (location.state as LocationState)?.from?.pathname || '/';

  const handleEmailAndPasswordSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    setSignupLoading(true);
    e.preventDefault();

    const paramId = searchParams.get('id');

    if (paramId == null || paramId.length === 0 || email == null || email.length === 0) {
      console.log('Missing registration params');
      setSignupLoading(false);
      return;
    }

    try {
      await registerUser({ displayName: displayName, password: password }, paramId);
      await globalState.loginWithEmailAndPassword(email, password);
      setSignupLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      console.log('Email and password signup failed:', error);
      setPasswordError(true);
      setDisplayNameError(true);
      setSignupLoading(false);
    }
  };

  React.useEffect(() => {
    if (globalState.user) {
      navigate('/', { replace: true });
    }
  }, [globalState.user, navigate]);

  React.useEffect(() => {
    const fetchEmail = async (userId: string) => {
      const user = await getUser(userId);
      if (user.email && user.status === 'pending') {
        setEmail(user.email);
      }
    };

    setSignupLoading(true);
    const paramId = searchParams.get('id');

    if (paramId === null) {
      setSignupLoading(false);
      return;
    }

    fetchEmail(paramId);
    setSignupLoading(false);
  }, [searchParams]);

  return (
    <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-center ${styles.mainContainer}`}>
      <div
        className={`d-flex flex-column align-items-center justify-content-around rounded p-sm-5 p-xs-0 m-3 ${styles.innerContainer}`}
      >
        <img src={newFrozenEyeLogo} alt='featureLogo' className='mb-sm-3' />
        {email.trim().length === 0 || !email || email === null ? (
          <p>Signup link is invalid!</p>
        ) : (
          <>
            <p>
              Signing up with <b>{email}</b>
            </p>

            <div className='px-4 mt-sm-5 w-100' style={{ maxWidth: '30rem' }}>
              <Form onSubmit={handleEmailAndPasswordSignup}>
                <FormGroup inline>
                  <InputGroup className='pb-1'>
                    <Input
                      invalid={displayNameError}
                      id='displayName'
                      name='displayName'
                      type='text'
                      placeholder='Display Name'
                      className={styles.passwordInput}
                      onChange={(e) => {
                        if (displayNameError) setDisplayNameError(false);
                        setDisplayName(e.target.value);
                      }}
                      value={displayName}
                      onFocus={() => setDisplayNameError(false)}
                    />
                    <FormFeedback>Email is incorrect</FormFeedback>
                  </InputGroup>
                  <InputGroup>
                    <Input
                      invalid={passwordError}
                      id='password'
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      className={styles.passwordInput}
                      onChange={(e) => {
                        if (passwordError) setPasswordError(false);
                        setPassword(e.target.value);
                      }}
                      value={password}
                      onFocus={() => setPasswordError(false)}
                    />
                    <InputGroupText className={styles.inputIcon}>
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        color='#145f92'
                        onClick={() => setShowPassword(!showPassword)}
                      ></FontAwesomeIcon>
                    </InputGroupText>
                    <FormFeedback>Password is incorrect</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <div className='d-grid'>
                  <Button color='primary' type='submit' disabled={signupLoading}>
                    {signupLoading ? <Spinner size='sm' /> : 'Register'}
                  </Button>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupPage;
