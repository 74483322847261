import React from 'react';

// Hook
export const usePrevious = <T extends any>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useIsMobileView = () => {
  const [useFullSizeNav, setUseFullSizeNav] = React.useState<boolean>(true);

  function handleResize() {
    // Manual breakpoint for mobile menu. Failed to make it dynamic for now..
    // TODO: implement a separate hook for the menu collapse which is not hard-coded to this breakpoint?
    if (window.innerWidth < 810) {
      setUseFullSizeNav(false);
    } else {
      setUseFullSizeNav(true);
    }
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !useFullSizeNav;
};
