import DownloadPage from '../Views/DownloadPage/DownloadPage';
import LoadingPage from '../Components/LoadingPage';
import NotFoundPage from '../NotFoundPage';
import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useLocation } from 'react-router-dom';
import { SystemAdminPage } from '../Views/SystemAdminPage/SystemAdminPage';
import { useGlobalState } from '../GlobalState/GlobalState';
import { UserRole } from '../Utils/types';
import SignupPage from '../Views/SignupPage/SignupPage';
import ForgotPasswordPage from '../Views/LoginPage/ForgotPasswordPage';
import NewPasswordPage from '../Views/SignupPage/NewPasswordPage';

const HomePage = React.lazy(() => import('../Views/HomePage/HomePage'));
const LoginPage = React.lazy(() => import('../Views/LoginPage/LoginPage'));
const MediaViewer = React.lazy(() => import('../Views/mediaViewer/MediaViewer'));
const CameraPage = React.lazy(() => import('../Views/Camera/CameraPage'));
const ProjectsContext = React.lazy(() => import('../Views/ProjectsPage/ProjectsContext'));
const ProjectsList = React.lazy(() => import('../Views/ProjectsPage/ProjectsList'));
const ProjectView = React.lazy(() => import('../Views/ProjectsPage/ProjectView'));

const router = createBrowserRouter([
  {
    path: '/settings',
    element: (
      <RequireAuth>
        <React.Suspense fallback={<LoadingPage />}>
          <SystemAdminPage />
        </React.Suspense>
      </RequireAuth>
    ),
    children: [
      {
        path: '/settings/:organisationId'
      }
    ]
  },
  {
    path: '/projects',

    element: (
      <RequireAdminAuth>
        <React.Suspense fallback={<LoadingPage />}>
          <ProjectsContext />
        </React.Suspense>
      </RequireAdminAuth>
    ),
    children: [
      { path: '/projects', index: true, element: <ProjectsList /> },
      { path: '/projects/:projectId', element: <ProjectView /> }
    ]
  },
  {
    path: '/login',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <LoginPage />
      </React.Suspense>
    )
  },
  {
    path: '/signup',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <SignupPage />
      </React.Suspense>
    )
  },
  {
    path: '/forgot-password',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <ForgotPasswordPage />
      </React.Suspense>
    )
  },
  {
    path: '/reset-password',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <NewPasswordPage />
      </React.Suspense>
    )
  },
  {
    path: '/',
    element: (
      <RequireAdminAuth>
        <React.Suspense fallback={<LoadingPage />}>
          <HomePage />
        </React.Suspense>
      </RequireAdminAuth>
    )
  },
  {
    path: '/media/:id',
    element: (
      <RequireAuth>
        <React.Suspense fallback={<LoadingPage />}>
          <MediaViewer />
        </React.Suspense>
      </RequireAuth>
    )
  },
  {
    path: '/download/:urlId/:filetype/:filename',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <DownloadPage />
      </React.Suspense>
    )
  },
  {
    path: '/camera/:linkId?',
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <CameraPage />
      </React.Suspense>
    )
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
]);

export const AppRoutes = () => <RouterProvider router={router} />;

function RequireAuth({ children }: { children: JSX.Element }) {
  let globalState = useGlobalState();
  let location = useLocation();

  if (!globalState.loading && !globalState.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}

function RequireAdminAuth({ children }: { children: JSX.Element }) {
  let globalState = useGlobalState();
  let location = useLocation();

  if (!globalState.loading && !globalState.user) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (
    !globalState.loading &&
    globalState.user?.role !== UserRole.SUPERADMIN &&
    globalState.user?.role !== UserRole.ADMIN
  ) {
    // TODO: decide what the default page should actually be
    return <Navigate to='/' replace />;
  }

  return children;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RequireSuperadminAuth({ children }: { children: JSX.Element }) {
  let globalState = useGlobalState();
  let location = useLocation();

  if (!globalState.loading && !globalState.user) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (!globalState.loading && globalState.user?.role !== UserRole.SUPERADMIN) {
    return <Navigate to='/' replace />;
  }

  return children;
}
