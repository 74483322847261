import axios from 'axios';
import React from 'react';
import styles from './SystemAdminPage.module.scss';
import { BlockModule } from './BlockModule';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { createUserByEmail, deleteUser, editUser, reSendActivationEmail } from '../../Firebase/http';
import { faFileExport, faFileImport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { OrganisationType, UserDataType, UserDataTypeWithFID, UserRole, UserStatus, WithFID } from '../../Utils/types';
import { SortableTable } from '../../Components/SortableTable';
import { RoundedIconButton } from '../../Components/Buttons';
import { useCSVReader, usePapaParse } from 'react-papaparse';
import { toast } from 'react-toastify';
import { useGlobalState } from '../../GlobalState/GlobalState';

type AccountModuleProps = {
  activeOrg: (OrganisationType & WithFID) | undefined;
  users: UserDataTypeWithFID[];
};

export const AccountBlock = ({ activeOrg, users }: AccountModuleProps) => {
  const [showModalCreate, setShowModalCreate] = React.useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);
  const [showModalActivation, setShowModalActivation] = React.useState<boolean>(false);
  const [showModalPending, setShowModalPending] = React.useState<boolean>(false);

  const [userToModify, setUserToModify] = React.useState<UserDataTypeWithFID>();
  const { CSVReader } = useCSVReader();
  const { jsonToCSV } = usePapaParse();

  const importCsv = (results: { data: Array<unknown>; errors: Array<unknown>; meta: Array<unknown> }) => {
    if (activeOrg?.fId == null) {
      toast.error('Organisation ID could not be found.');
      return;
    }

    let unsuccessfulImports = [];

    for (let i = 0; i < results.data.length; i++) {
      const accountObject = results.data[i] as Object;

      if ('email' in accountObject && 'role' in accountObject) {
        // TODO: Check all kinds of CSV formatting problems that might arise.
        // TODO: Check for duplicates within CSV.
        if (
          users.some((u) => {
            // eslint-disable-next-line eqeqeq
            return u.email == accountObject.email;
          })
        ) {
          continue;
        }

        try {
          createUserByEmail({
            email: (accountObject.email as string).trim(),
            role: (accountObject.role as string).toUpperCase() === 'ADMIN' ? UserRole.ADMIN : UserRole.USER,
            organisationId: activeOrg.fId,
            status: UserStatus.PENDING
          });
        } catch (error) {
          unsuccessfulImports.push(i);
        }
      } else {
        toast.error('CSV is incorrectly formatted');
        return;
      }
    }
    if (unsuccessfulImports.length === 0) {
      toast.success('Import successful');
    } else if (unsuccessfulImports.length === results.data.length) {
      toast.error('CSV failed to import');
    } else {
      const warning = `Import partially successful, but line${
        unsuccessfulImports.length === 1 ? '' : 's'
      } ${unsuccessfulImports.map((e) => e + 1)} failed to import.`;
      toast.warning(warning);
      console.warn(warning);
    }
  };

  const exportCsv = () => {
    const csvString = jsonToCSV(
      users
        .filter((u) => u.organisationId === activeOrg?.fId && u.role !== UserRole.SUPERADMIN)
        .map((u): Pick<UserDataType, 'displayName' | 'email' | 'role'> => {
          return { displayName: u.displayName, email: u.email, role: u.role };
        })
    );
    const csvBlob = new Blob([csvString], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const csvLink = document.createElement('a');
    csvLink.download = 'accounts.csv';
    csvLink.href = csvUrl;
    csvLink.click();
    URL.revokeObjectURL(csvLink.href);
  };

  const globalState = useGlobalState();
  const organisationColor = globalState.organisation?.organisationColor;

  return (
    <>
      <CSVReader onUploadAccepted={importCsv} config={{ header: true, skipEmptyLines: true }}>
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
          <BlockModule
            title={
              <div className='d-flex mb-2 gap-2'>
                <h2 className='ms-3'>Accounts</h2>
                <RoundedIconButton
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                  icon={faPlus}
                  onClick={() => setShowModalCreate(true)}
                />
                <RoundedIconButton
                  icon={faFileImport}
                  {...getRootProps()}
                  tooltip='Import CSV'
                  tooltipPosition='top'
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                />
                <RoundedIconButton
                  icon={faFileExport}
                  onClick={exportCsv}
                  tooltip='Export CSV'
                  tooltipPosition='top'
                  style={{
                    backgroundColor: organisationColor ?? 'primary',
                    borderColor: organisationColor ?? 'primary'
                  }}
                />
              </div>
            }
          >
            <div>
              <SortableTable
                data={users.filter((u) => u.organisationId === activeOrg?.fId && u.role !== UserRole.SUPERADMIN)}
                columns={[
                  { label: 'Name', key: 'displayName' },
                  { label: 'Email', key: 'email' },
                  { label: 'Role', key: 'role' },
                  { label: 'Status', key: 'status' }
                ]}
                defaultSortColumn='displayName'
                actions={{
                  actions: {
                    delete: (id) => {
                      setUserToModify(users.find((u) => u.fId === id));
                      setShowModalDelete(true);
                    },
                    edit: (id) => {
                      setUserToModify(users.find((u) => u.fId === id));
                      setShowModalEdit(true);
                    },
                    handleStatus: (id) => {
                      setUserToModify(users.find((u) => u.fId === id));
                      setShowModalActivation(true);
                    },
                    handlePending: (id) => {
                      setUserToModify(users.find((u) => u.fId === id));
                      setShowModalPending(true);
                    }
                  },
                  idKey: 'fId'
                }}
                emptyPlaceholder="No accounts exist for this organisation! Click the '+' button to add one!"
              />
            </div>
          </BlockModule>
        )}
      </CSVReader>

      <EditAndCreateModal
        activeOrg={activeOrg}
        users={users}
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        modalType='create'
        userToModify={userToModify}
      />

      <EditAndCreateModal
        activeOrg={activeOrg}
        users={users}
        isOpen={showModalEdit}
        setIsOpen={setShowModalEdit}
        modalType='edit'
        userToModify={userToModify}
      />

      <DeleteModal userToModify={userToModify} isOpen={showModalDelete} setIsOpen={setShowModalDelete} />

      <ActivationModal userToModify={userToModify} isOpen={showModalActivation} setIsOpen={setShowModalActivation} />

      <PendingModal userToModify={userToModify} isOpen={showModalPending} setIsOpen={setShowModalPending} />
    </>
  );
};

type PendingModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userToModify: UserDataTypeWithFID | undefined;
};

const PendingModal = ({ isOpen, setIsOpen, userToModify }: PendingModalProps) => {
  const [isLoadingRequest, setIsLoadingRequest] = React.useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>{userToModify?.status === UserStatus.ACTIVE ? 'Deactivate' : 'Activate'} account</ModalHeader>
      <ModalBody>
        <p>Are you sure that you want to resend a activation link to this account?</p>
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!userToModify) return;
            setIsLoadingRequest(true);
            try {
              await reSendActivationEmail(userToModify, userToModify.fId);
              setIsLoadingRequest(false);
              setIsOpen(false);
            } catch (error) {
              console.log(error);
              setIsLoadingRequest(false);
              setIsOpen(false);
            }
          }}
          className='me-auto'
          style={{ minWidth: 80 }}
          disabled={isLoadingRequest}
        >
          {isLoadingRequest ? <Spinner size='sm' /> : 'Send email'}
        </Button>
        <Button color='danger' disabled={isLoadingRequest} onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type ActivationModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userToModify: UserDataTypeWithFID | undefined;
};

const ActivationModal = ({ isOpen, setIsOpen, userToModify }: ActivationModalProps) => {
  const [isLoadingRequest, setIsLoadingRequest] = React.useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>{userToModify?.status === UserStatus.ACTIVE ? 'Deactivate' : 'Activate'} account</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to {userToModify?.status === UserStatus.ACTIVE ? 'deactivate' : 'activate'} this
          account?
        </p>
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!userToModify) return;
            setIsLoadingRequest(true);
            try {
              if (userToModify.status === UserStatus.ACTIVE) {
                await editUser({ ...userToModify, status: UserStatus.INACTIVE }, userToModify.fId);
                setIsLoadingRequest(false);
                setIsOpen(false);
              } else if (userToModify.status === UserStatus.INACTIVE) {
                await editUser({ ...userToModify, status: UserStatus.ACTIVE }, userToModify.fId);
                setIsLoadingRequest(false);
                setIsOpen(false);
              } else if (userToModify.status === undefined || null) {
                await editUser({ ...userToModify, status: UserStatus.ACTIVE }, userToModify.fId);
                setIsLoadingRequest(false);
                setIsOpen(false);
              } else {
                console.log('Account is neither active nor inactive. Activate the account.');
                setIsLoadingRequest(false);
                setIsOpen(false);
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className='me-auto'
          style={{ minWidth: 80 }}
          disabled={isLoadingRequest}
        >
          {isLoadingRequest ? (
            <Spinner size='sm' />
          ) : userToModify?.status === UserStatus.ACTIVE ? (
            'Deactivate'
          ) : (
            'Activate'
          )}
          {}
        </Button>
        <Button color='danger' disabled={isLoadingRequest} onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userToModify: UserDataTypeWithFID | undefined;
};
const DeleteModal = ({ isOpen, setIsOpen, userToModify }: DeleteModalProps) => {
  const [requestError, setRequestError] = React.useState<string>('');
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>Delete Account</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete this account?</p>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!userToModify) return;
            setIsLoadingRequest(true);
            setRequestError('');

            try {
              await deleteUser(userToModify?.fId);
              setIsOpen(false);
              setIsLoadingRequest(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error adding user: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to add user');
              }
              setIsLoadingRequest(false);
            }
          }}
          className='me-auto'
          style={{ minWidth: 80 }}
          disabled={isLoadingRequest}
        >
          {isLoadingRequest ? <Spinner size='sm' /> : 'Yes'}
        </Button>
        <Button color='danger' onClick={() => setIsOpen(false)} disabled={isLoadingRequest}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type EditAndCreateModalProps = {
  activeOrg: (OrganisationType & WithFID) | undefined;
  isOpen: boolean;
  modalType: 'edit' | 'create';
  setIsOpen: (isOpen: boolean) => void;
  users: UserDataTypeWithFID[];
  userToModify: UserDataTypeWithFID | undefined;
};
const EditAndCreateModal = ({
  activeOrg,
  isOpen,
  modalType,
  setIsOpen,
  users,
  userToModify
}: EditAndCreateModalProps) => {
  const [email, setEmail] = React.useState<string>('');
  const [role, setRole] = React.useState<UserRole>(UserRole.USER);

  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');

  React.useEffect(() => {
    if (isOpen) {
      if (modalType !== 'create') {
        setRole(!userToModify ? UserRole.USER : userToModify.role);
      }
    } else {
      setRole(UserRole.USER);
      setRequestError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader> {modalType === 'create' ? 'Create new' : 'Edit'} account</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for='emailCreate' className={styles.label}>
              Email
            </Label>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              name='emailCreate'
              type='email'
              placeholder='Enter an email address for the user...'
              defaultValue={modalType === 'create' ? '' : userToModify?.email}
            />
          </FormGroup>

          <FormGroup>
            <Label for='roleCreate' className={styles.label}>
              Role
            </Label>
            <Input
              id='roleCreate'
              name='roleCreate'
              type='select'
              onChange={(e) => setRole(e.target.value as UserRole)}
              defaultValue={modalType === 'create' ? '' : (userToModify?.role as UserRole)}
            >
              <option value={UserRole.USER} onClick={() => setRole(UserRole.USER)}>
                User
              </option>
              <option value={UserRole.ADMIN} onClick={() => setRole(UserRole.USER)}>
                Admin
              </option>
            </Input>
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '8em' }}
          disabled={!activeOrg || !email || formSubmitLoading}
          onClick={async () => {
            if (modalType === 'edit') {
              if (!userToModify) return;
              if (userToModify?.email === email && userToModify.role === role) {
                setIsOpen(false);
                return;
              }
            }

            setFormSubmitLoading(true);
            setRequestError('');
            try {
              if (modalType === 'create') {
                await createUserByEmail({
                  organisationId: activeOrg?.fId!,
                  role,
                  email: email.trim(),
                  status: UserStatus.PENDING
                });
              } else {
                await editUser(
                  {
                    organisationId: activeOrg?.fId!,
                    role,
                    email: email.trim()
                  },
                  userToModify!.fId
                );
              }
              setIsOpen(false);
              setFormSubmitLoading(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error updating user: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to update user');
              }
              setFormSubmitLoading(false);
            }
          }}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : `${modalType === 'create' ? 'Add' : 'Update'} user`}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
