import React from 'react';
import styles from './SortableTable.module.scss';
import {
  faCaretDown,
  faCaretUp,
  faEye,
  faPen,
  faPowerOff,
  faQrcode,
  faRedoAlt,
  faRepeat,
  faTrash,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoundedIconButton } from './Buttons';
import { Table } from 'reactstrap';
import { useGlobalState } from '../GlobalState/GlobalState';

type SortableColumnsType = {
  label: string;
  key: string;
};

type ActionsPropType = {
  view?: (id: string) => void;
  edit?: (id: string) => void;
  delete?: (id: string) => void;
  redo?: (id: string) => void;
  handleStatus?: (id: string) => void;
  handlePending?: (id: string) => void;
  qrCode?: (id: string) => void;
};

type SortableTableProps = {
  columns: SortableColumnsType[];
  data: { fId: any }[] & any[];
  defaultSortColumn: string;
  emptyPlaceholder?: string;
  actions?: { actions: ActionsPropType; idKey: string };
  iterationKey?: string;
};

enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

type ActionButtonProps = {
  icon: IconDefinition;
  onClick: () => void;
  show: boolean;
};
const ActionButton = ({ icon, onClick, show }: ActionButtonProps) => {
  const globalState = useGlobalState();
  const orgColor = globalState.organisation?.organisationColor;
  return (
    <>
      {show && (
        <RoundedIconButton
          onClick={onClick}
          icon={icon}
          style={{ backgroundColor: orgColor ?? 'primary', borderColor: orgColor }}
        />
      )}
    </>
  );
};

// TODO: searchable
// TODO: pagination?
export const SortableTable = ({
  actions,
  columns,
  data,
  defaultSortColumn,
  emptyPlaceholder,
  iterationKey
}: SortableTableProps) => {
  const [tableData, setTableData] = React.useState<any[]>(data);
  const [currentSortColumn, setCurrentSortColumn] = React.useState<string>(defaultSortColumn);
  const [currentSortOrder, setCurrentSortOrder] = React.useState<SortOrder>(SortOrder.ASCENDING);

  const changeSorting = (columnKey: string) => {
    const newSortOrder =
      columnKey === currentSortColumn && currentSortOrder === SortOrder.ASCENDING
        ? SortOrder.DESCENDING
        : SortOrder.ASCENDING;

    setCurrentSortOrder(newSortOrder);
    setCurrentSortColumn(columnKey);

    doSorting(newSortOrder, columnKey);
  };

  const numberSorting = (a: any, b: any, column: string) => {
    const numberA = parseInt(a[column], 10);
    const numberB = parseInt(b[column], 10);

    if (!isNaN(numberA) && !isNaN(numberB)) {
      return numberA - numberB;
    } else {
      return a[column].toString().localeCompare(b[column].toString(), undefined, { numeric: true });
    }
  };

  const doSorting = (order: SortOrder, column: string, data?: any) => {
    const dataToSort = data || tableData;

    if (!dataToSort || !column || !order) return;

    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[column] === null || a[column] === undefined) return 1;
      if (b[column] === null || b[column] === undefined) return -1;
      return numberSorting(a, b, column) * (order === SortOrder.ASCENDING ? 1 : -1);
    });

    setTableData(sortedData);
  };

  React.useEffect(() => {
    doSorting(currentSortOrder, currentSortColumn, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {tableData.length < 1 ? (
        <div>{emptyPlaceholder || 'No data'}</div>
      ) : (
        <Table>
          <thead>
            <tr>
              {columns.map((c) => (
                <th key={c.key} onClick={() => changeSorting(c.key)} className={`pointer ${styles['table-header']}`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    {c.label}
                    <span
                      className={`d-flex flex-column ${styles.carets} ${
                        currentSortColumn === c.key ? 'text-black' : ''
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size='sm'
                        style={{
                          visibility:
                            currentSortColumn === c.key && currentSortOrder === SortOrder.DESCENDING
                              ? 'hidden'
                              : 'unset',
                          marginBottom: '-0.4em'
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size='sm'
                        style={{
                          visibility:
                            currentSortColumn === c.key && currentSortOrder === SortOrder.ASCENDING ? 'hidden' : 'unset'
                        }}
                      />
                    </span>
                  </div>
                </th>
              ))}
              {actions && <th className={styles['small-cell']}>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {tableData.map((data) => (
              <tr key={iterationKey ? data[iterationKey] : data.fId}>
                {columns.map(({ key }) => (
                  <td style={{ verticalAlign: 'middle' }} key={key}>
                    {data[key] ? data[key].toString() : ''}
                  </td>
                ))}
                {actions && (
                  <td>
                    <div className='d-flex gap-1' style={{ width: 'max-content' }}>
                      <ActionButton
                        show={!!actions.actions.qrCode}
                        icon={faQrcode}
                        onClick={() => actions.actions.qrCode!(data[actions.idKey])}
                      />
                      <ActionButton
                        show={!!actions.actions.view}
                        icon={faEye}
                        onClick={() => actions.actions.view!(data[actions.idKey])}
                      />
                      <ActionButton
                        show={!!actions.actions.edit}
                        icon={faPen}
                        onClick={() => actions.actions.edit!(data[actions.idKey])}
                      />
                      <ActionButton
                        show={!!actions.actions.redo}
                        icon={faRedoAlt}
                        onClick={() => actions.actions.redo!(data[actions.idKey])}
                      />
                      <ActionButton
                        show={!!actions.actions.delete}
                        icon={faTrash}
                        onClick={() => actions.actions.delete!(data[actions.idKey])}
                      />
                      {data.status !== 'pending' ? (
                        <ActionButton
                          show={!!actions.actions.handleStatus}
                          icon={faPowerOff}
                          onClick={() => actions.actions.handleStatus!(data[actions.idKey])}
                        />
                      ) : (
                        <ActionButton
                          show={!!actions.actions.handlePending}
                          icon={faRepeat}
                          onClick={() => actions.actions.handlePending!(data[actions.idKey])}
                        />
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
