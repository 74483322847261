import { faPen } from '@fortawesome/free-solid-svg-icons';
import React, { ChangeEvent } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  FormFeedback
} from 'reactstrap';
import { RoundedIconButton } from '../../Components/Buttons';
import { BlockModule } from './BlockModule';
import styles from './SystemAdminPage.module.scss';
import { CustomOrganisationDataType, DocumentDataType, WithFID } from '../../Utils/types';
import axios from 'axios';
import { editOrganisation, uploadImageByOrgId } from '../../Firebase/http';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { GeoPoint, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

type OrganisationModuleProps = { organisation: CustomOrganisationDataType & WithFID };

export const OrganisationBlock = (props: OrganisationModuleProps) => {
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);
  const globalState = useGlobalState();
  const organisationColor = globalState.organisation?.organisationColor;

  return (
    <>
      <BlockModule
        title={
          <div className='d-flex mb-2 gap-2'>
            <h2 className='ms-3'>Your Organisation</h2>
            <RoundedIconButton
              icon={faPen}
              onClick={() => setShowModalEdit(true)}
              tooltip='Edit'
              style={{ backgroundColor: organisationColor ?? 'primary', borderColor: organisationColor ?? 'primary' }}
            />
          </div>
        }
      >
        <div>
          <Form>
            <FormGroup>
              <Label className={styles.label} for='1'>
                Name
              </Label>
              <Input className={styles['input-readonly']} readOnly placeholder={props.organisation.displayName}></Input>
            </FormGroup>
          </Form>
        </div>
      </BlockModule>

      <EditModal organisationToModify={props.organisation} isOpen={showModalEdit} setIsOpen={setShowModalEdit} />
    </>
  );
};

type EditModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  organisationToModify: CustomOrganisationDataType & WithFID;
};
const EditModal = ({ isOpen, setIsOpen, organisationToModify }: EditModalProps) => {
  const [displayName, setDisplayName] = React.useState<string>('');
  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');
  const globalState = useGlobalState();
  const [orgColor, setOrgColor] = React.useState<string | undefined>(organisationToModify?.organisationColor);
  const [orgColorIsCorrectlyFormatted, setOrgColorIsCorrectlyFormatted] = React.useState<boolean>(true);
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(organisationToModify?.organisationLogo);
  const storage = getStorage();

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e: React.MouseEvent) => {
    e.preventDefault();
    setFormSubmitLoading(true);
    setRequestError('');
    try {
      editOrganisation(
        { displayName: displayName, organisationColor: orgColor, organisationLogo: logoUrl },
        organisationToModify.fId
      );
      globalState.setOrganisation({ ...globalState.organisation!, displayName: displayName });
      setIsOpen(false);
      setFormSubmitLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setRequestError('Error updating user: ' + error.response?.data);
      } else {
        console.log(error);
        setRequestError('Failed to update user');
      }
      setFormSubmitLoading(false);
    }
  };

  const fetchIconFromFirebaseStorage = async (orgId: string, logoName: string) => {
    try {
      const newUrl = await getDownloadURL(ref(storage, `${orgId}/${logoName}`));
      setLogoUrl(newUrl);
    } catch (error) {
      console.error('Error fetching logo URL:', error);
      return '';
    }
  };

  const fetchUserFileName = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0 && organisationToModify?.fId && event.target.files !== null) {
      const uploadedFile = fileInput.files[0];
      const newFileName = Timestamp.now() + uploadedFile.name; //Generates a unique name for the logofile, otherwise returns 403 error if name already exists in storage

      const newFileReader = new FileReader();
      newFileReader.onload = async (e) => {
        const form = new FormData();
        if (e.target?.result) {
          const file = new File([e.target.result], newFileName);
          form.append('file', file);
          form.append(
            'mediaDoc',
            JSON.stringify({
              fileName: newFileName,
              fileType: 'image',
              timestamp: Timestamp.now(),
              location: new GeoPoint(0, 0)
            } as DocumentDataType)
          );
          await uploadImageByOrgId(organisationToModify.fId, form);
          await fetchIconFromFirebaseStorage(organisationToModify?.fId, newFileName);
        }
      };
      newFileReader.readAsArrayBuffer(event.target.files[0]);
    } else {
      console.log('No file found.');
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setRequestError('');
      setDisplayName(organisationToModify.displayName);
      setOrgColor(organisationToModify.organisationColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader>Update organisation info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className={styles.label}>Name</Label>
            <Input
              onChange={(e) => setDisplayName(e.target.value)}
              type='text'
              placeholder='Enter a new display name...'
              defaultValue={organisationToModify?.displayName}
            />
          </FormGroup>
          <FormGroup>
            <Label className={styles.label}>Color</Label> <br />
            Click colorbox to choose color or type in hexcode below
            <div className='d-flex flex-row align-items-center'>
              <input
                defaultValue={organisationToModify?.organisationColor}
                type='color'
                onChange={(e) => setOrgColor(e.target.value)}
              />
              <Input
                onChange={(e) => {
                  if (e.target.value.match(/^#(?:(?:[\da-f]{3}){1,2}|(?:[\da-f]{4}){1,2})$/i)) {
                    setOrgColorIsCorrectlyFormatted(true);
                    setOrgColor(e.target.value);
                  } else {
                    setOrgColorIsCorrectlyFormatted(false);
                  }
                }}
                type='text'
                placeholder='Enter a color for the organisation'
                defaultValue={organisationToModify.organisationColor}
              ></Input>
            </div>
          </FormGroup>
          <FormGroup>
            <Label className={styles.label}>Logo</Label>
            <Input type='file' onChange={async (e) => fetchUserFileName(e)}></Input>
            <FormFeedback>
              {!orgColorIsCorrectlyFormatted && <p>Organisation's color hexcode is not correctly formatted</p>}
            </FormFeedback>
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '12em' }}
          disabled={
            !displayName || displayName.length < 1 || formSubmitLoading || !orgColorIsCorrectlyFormatted || !orgColor
          }
          onClick={onSubmit}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : 'Update organisation'}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
