import React from 'react';
import { Container, Spinner } from 'reactstrap';

type Props = { text?: string };
const LoadingPage: React.FC<Props> = (props) => {
  return (
    <Container fluid className='d-flex flex-column justify-content-center align-items-center min-vh-100'>
      <Spinner style={{ width: '4rem', height: '4rem' }}></Spinner>
      <h3 className='sr-only'>{props.text ?? 'loading...'}</h3>
    </Container>
  );
};

export default LoadingPage;
