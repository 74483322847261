import ENV_VARIABLES from '../../config';

export const removeHttp = (url: string) => {
  if (url.startsWith('https://')) {
    const https = 'https://';
    return url.slice(https.length);
  }

  if (url.startsWith('http://')) {
    const http = 'http://';
    return url.slice(http.length);
  }

  return url;
};

// const getImageMeta = (url: string): Promise<HTMLImageElement> => {
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.onload = () => resolve(img);
//     img.onerror = () => reject();
//     img.src = url;
//   });
// };

export const generateFileName = (fileName?: string, extension?: string): string => {
  if (fileName) {
    if (extension) {
      return fileName.split('.')[0] + '.' + extension;
    }
    return fileName;
  }
  return 'DOWNLOADED_FILE';
};

// Additional base64 encoding step needed to prevent blank PDF: https://stackoverflow.com/questions/53198085/piping-pdf-to-express-response-results-in-blank-pdf
export const base64ToArrayBuffer = (data: string) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const getParameterByName = (name: string, url: string = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const downloadURI = (uri: string, fileName: string, extension?: string) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    const blob = xhr.response;
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = generateFileName(fileName, extension);
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  };
  xhr.open('GET', uri);
  xhr.send();
};

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getCameraLink = (urlId: string | undefined) => `https://${ENV_VARIABLES.authDomain}/camera/${urlId}`;
export const getProjectCameraLink = (urlId: string, projectId: string) =>
  `https://${ENV_VARIABLES.authDomain}/camera/${urlId}?project=${projectId}`;
