import axios, { AxiosRequestConfig } from 'axios';
import ENV_VARIABLES from '../config';
import format from 'date-fns/format';
import sv from 'date-fns/locale/sv';
import { base64ToArrayBuffer } from '../Utils/HelperFunctions/helperFunctions';
import {
  CameraLinkDocumentType,
  CustomDocumentDataType,
  CustomOrganisationDataType,
  CustomProjectDataType,
  ProjectDataType,
  CustomStatusDataType,
  CustomTokenResponseType,
  CustomUserDataType,
  OrganisationType,
  StatusDataType,
  UserDataType,
  WithFID
} from '../Utils/types';
import { FirebaseService } from './Firebase';

const firebaseService = FirebaseService;
const client = axios.create({ baseURL: ENV_VARIABLES.functionsBaseUrl });

/**
 * Send credentials to FB functions express endpoint and receive the custom token.
 * use custom token to login using `signInWithCustomToken`
 */
export const getFBCustomToken = async (password: string) => {
  const { data } = await client.post<CustomTokenResponseType>(
    '/v1/custom-token',
    { password },
    { headers: { 'Content-Type': 'application/json' } }
  );
  return data;
};

/**
 * Can pass organisationId as filter params along AxiosRequestConfig to filter users by organisation ID
 * @param {String} organisationId
 * @returns
 */
export const getAllUsers = async (config?: AxiosRequestConfig): Promise<CustomUserDataType[]> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomUserDataType[]>(`/v1/user`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    ...config
  });
  return data;
};

export const getUser = async (id: string) => {
  const { data } = await client.get<CustomUserDataType>(`/v1/user/${id}`, {
    headers: { 'Content-Type': 'application/json' }
  });
  return data;
};

export const createUser = async (user: UserDataType) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomUserDataType>(
    '/v1/user',
    { user: { ...user } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const createUserByEmail = async (user: UserDataType) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomUserDataType>(
    '/v2/user',
    { userData: { ...user } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const editUser = async (user: Partial<UserDataType>, id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomUserDataType>(
    `/v1/user/${id}`,
    { user: { ...user } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const deleteUser = async (id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.delete<CustomUserDataType>(`/v1/user/${id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const registerUser = async (user: Partial<UserDataType>, id: string) => {
  const { data } = await client.patch<CustomUserDataType>(
    `/v1/user/${id}`,
    { user: { ...user } },
    { headers: { 'Content-Type': 'application/json' } }
  );
  return data;
};

export const getOrganisation = async (id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomOrganisationDataType>(`/v1/org/${id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const getDefaultOrganisation = async () => {
  const { data } = await client.get<CustomOrganisationDataType>(`/v1/default-org`, {
    headers: { 'Content-Type': 'application/json' }
  });
  return data;
};

export const getAllOrganisations = async () => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomOrganisationDataType[]>(`/v1/org`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const getOrganisationByUrlId = async (id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomOrganisationDataType>(`/v1/org/from-url/${id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const getOrganisationByCameraLink = async (id: string) => {
  const { data } = await client.get<CustomOrganisationDataType>(`/v1/org/from-camera-url/${id}`, {
    headers: { 'Content-Type': 'application/json' }
  });
  return data;
};

export const getCameraLinkDoc = async (id: string) => {
  const { data } = await client.get<CameraLinkDocumentType & WithFID>(`/v1/camera-link/${id}`, {
    headers: { 'Content-Type': 'application/json' }
  });
  return data;
};

export const createOrganisation = async (org: OrganisationType) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomOrganisationDataType>(
    '/v1/org',
    { org: { ...org } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const editOrganisation = async (org: Partial<OrganisationType>, id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomOrganisationDataType>(
    `/v1/org/${id}`,
    { org: { ...org } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const assignAdminMessageToMedia = async (message: string, mediaId: string, organisationId?: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomDocumentDataType>(
    `/v1/media/${mediaId}/admin-message`,
    { adminMessage: message, orgId: organisationId },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const assignStatusToMedia = async (statusId: string, mediaId: string, organisationId?: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomDocumentDataType>(
    `/v1/media/${mediaId}/status`,
    { statusId, orgId: organisationId },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const assignIsReadStatusToMedia = async (isRead: boolean, mediaId: string, organisationId?: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomDocumentDataType>(
    `/v1/media/${mediaId}/read`,
    { isRead, orgId: organisationId },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const assignUserToMedia = async (
  userId: string,
  mediaId: string,
  organisationId: string
): Promise<CustomDocumentDataType> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomDocumentDataType>(
    `/v1/media/${mediaId}/assignee`,
    { orgId: organisationId, userId },
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    }
  );
  return data;
};

export const deleteOrganisation = async (id: string, userId: string | undefined, password: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.delete(`/v1/org/${id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    data: {
      userId,
      password
    }
  });
  return data;
};

export const getBackups = async () => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get('/v1/backups', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const restoreBackup = async (id: string, dateString: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post(
    '/v1/restore-backup',
    { organisationId: id, date: dateString },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const downloadPDFReport = async (organisationId: string, content: CustomDocumentDataType | string) => {
  try {
    const { data } = await client.get('/v1/pdf-report', {
      headers: { 'Content-Type': 'application/json' },
      params: {
        organisationId,
        data:
          typeof content === 'string'
            ? { fileName: content }
            : {
                fileName: content.fileName,
                docId: content.docId,
                adminMessage: content.adminMessage,
                message: content.message,
                location: `[${content.location.latitude}, ${content.location.longitude}]`,
                timestamp: format(content.timestamp.toDate(), 'yyyy-MM-dd, HH:mm:ss', { locale: sv })
              }
      }
    });

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(data)]));
    a.download = 'rapport.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.log(error);
  }
};

export const downloadAsPDF = async (organisationId: string, fileName: string) => {
  try {
    const { data } = await client.get('/v1/pdf', {
      headers: { 'Content-Type': 'application/json' },
      params: {
        organisationId,
        fileName
      }
    });

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(data)]));
    a.download = `${fileName.split('.')[0]}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.log(error);
  }
};

export const uploadMediaByOrgId = async (orgId: string, cameraId: string, form: FormData) => {
  const { data } = await client.post<CustomDocumentDataType>(`/v1/media/${orgId}/${cameraId}`, form, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return data;
};

export const getFilePublicUrl = async (orgId: string, fileName: string) => {
  const { data } = await client.get<string>('/v1/public-url', {
    params: { folder: orgId, fileName },
    headers: { 'Content-Type': 'application/json' }
  });
  return data;
};

export const generateCameraLink = async (
  orgId: string,
  permanent: boolean = false
): Promise<CameraLinkDocumentType & WithFID> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post(
    '/v1/camera-link',
    { organisationId: orgId, permanent },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const generateProjectCameraLink = async (
  organisationId: string,
  projectId: string,
  permanent: boolean = false
): Promise<CameraLinkDocumentType & WithFID> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post(
    '/v1/camera-link-project',
    { organisationId, projectId, permanent },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const getAllProjects = async (config?: AxiosRequestConfig): Promise<CustomProjectDataType[]> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomProjectDataType[]>(
    '/v1/projects',

    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }, ...config }
  );
  return data;
};

export const deleteProject = async (projectId: string): Promise<void> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  await client.delete(
    `/v1/projects/${projectId}`,

    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
};

export const updateProject = async (projectId: string, payload: ProjectDataType): Promise<CustomProjectDataType> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.patch<CustomProjectDataType>(`/v1/projects/${projectId}`, payload, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const createProject = async (payload: ProjectDataType): Promise<CustomProjectDataType> => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomProjectDataType>(`/v1/projects`, payload, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const addProjectToFile = async (projectId: string, fileId: string, organisationId: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomProjectDataType>(
    `/v1/projects/${projectId}/${fileId}/${organisationId}`,
    {},
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    }
  );
  return data;
};

export const getAllStatuses = async (config?: AxiosRequestConfig) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.get<CustomStatusDataType[]>('/v1/status', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    ...config
  });
  return data;
};

export const editStatus = async (status: Partial<StatusDataType>, id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.put<CustomStatusDataType>(
    `/v1/status/${id}`,
    { status: { ...status } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const createStatus = async (status: StatusDataType) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomStatusDataType>(
    '/v1/status',
    { status: { ...status } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const deleteStatus = async (id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.delete(`/v1/status/${id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const uploadImageByOrgId = async (orgId: string, form: FormData) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomDocumentDataType>(`/v1/media/${orgId}`, form, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` }
  });
  return data;
};

export const reSendActivationEmail = async (user: UserDataType, id: string) => {
  const token = await firebaseService.auth.currentUser?.getIdToken();
  const { data } = await client.post<CustomUserDataType>(
    `/v1/resendactivationemail/${id}`,
    { user: { ...user } },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const resetPassword = async (email: string) => {
  const { data } = await client.post<{ email: string; link: string }>(
    '/v1/password-reset',
    { email },
    { headers: { 'Content-Type': 'application/json' } }
  );
  return data;
};
