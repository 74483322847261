import newFrozenEyeLogo from '../../Utils/img/newFrozenEyeLogo.png';
import React from 'react';
import styles from './LoginPage.module.scss';
import { Button, Form, FormFeedback, FormGroup, Input, InputGroup, Spinner } from 'reactstrap';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../Firebase/http';
import { toast } from 'react-toastify';

const ForgotPasswordPage: React.FC<{}> = () => {
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [resetLoading, setResetLoading] = React.useState<boolean>(false);

  document.title = 'Forgot Password | The Frozen Eye';

  const sendPasswordResetEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    setResetLoading(true);
    e.preventDefault();
    try {
      await resetPassword(email);
      setResetLoading(false);
      toast.success('Password reset email sent.');
    } catch (error) {
      console.log('Password reset failed:', error);
      setEmailError(true);
      setResetLoading(false);
    }
  };

  React.useEffect(() => {
    if (globalState.user) {
      navigate('/', { replace: true });
    }
  }, [globalState.user, navigate]);

  return (
    <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-center ${styles.mainContainer}`}>
      <div
        className={`d-flex flex-column align-items-center justify-content-around rounded p-sm-5 p-xs-0 m-3 ${styles.innerContainer}`}
      >
        <img src={newFrozenEyeLogo} alt='featureLogo' className='mb-sm-3' />

        <div className='px-4 mt-sm-5 w-100' style={{ maxWidth: '30rem' }}>
          <Form onSubmit={sendPasswordResetEmail}>
            <FormGroup inline>
              <InputGroup className='pb-1'>
                <Input
                  invalid={emailError}
                  id='email'
                  name='email'
                  type='email'
                  placeholder='Email'
                  className={styles.passwordInput}
                  onChange={(e) => {
                    if (emailError) setEmailError(false);
                    setEmail(e.target.value);
                  }}
                  value={email}
                  onFocus={() => setEmailError(false)}
                />
                <FormFeedback>Email is incorrect</FormFeedback>
              </InputGroup>
            </FormGroup>
            <div className='d-grid'>
              <Button color='primary' type='submit' disabled={resetLoading}>
                {resetLoading ? <Spinner size='sm' /> : 'Send password reset email'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
