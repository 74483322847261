import React from 'react';

type AdminPanelBlockProps = {
  title: string | JSX.Element;
  children: any;
};

export const BlockModule = React.forwardRef<HTMLDivElement, AdminPanelBlockProps>((props, ref) => {
  return (
    <div ref={ref} style={{ maxWidth: '55em', width: '100%' }}>
      {typeof props.title === 'string' ? (
        <h2 className='mb-3 ms-3 me-2 d-inline-block'>{props.title}</h2>
      ) : (
        <>{props.title}</>
      )}
      <div className='bg-white shadow-sm rounded p-3 mb-5 overflow-auto'>{props.children}</div>
    </div>
  );
});
