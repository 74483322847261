import axios from 'axios';
import React from 'react';
import styles from './SystemAdminPage.module.scss';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import { BlockModule } from './BlockModule';
import { createOrganisation, deleteOrganisation } from '../../Firebase/http';
import { faCheck, faEye, faEyeSlash, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganisationType, WithCreationDate, WithFID } from '../../Utils/types';
import { RoundedIconButton } from '../../Components/Buttons';
import { SortableTable } from '../../Components/SortableTable';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useNavigate } from 'react-router-dom';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth';
import { toast } from 'react-toastify';

type OrganisationsBlockProps = {
  orgs: (OrganisationType & WithCreationDate & WithFID)[];
  hasFinishedLoading: boolean;
};
export const OrganisationsBlock = ({ orgs, hasFinishedLoading }: OrganisationsBlockProps) => {
  const [showModalCreate, setShowModalCreate] = React.useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);

  const [orgToDelete, setOrgToDelete] = React.useState<OrganisationType & WithCreationDate & WithFID>();

  const navigate = useNavigate();
  const globalState = useGlobalState();
  const orgColor = globalState.organisation?.organisationColor;

  return (
    <>
      <BlockModule
        title={
          <div className='d-flex mb-2 gap-2'>
            <h2 className='ms-3'>Organisations</h2>
            <RoundedIconButton
              style={{ backgroundColor: orgColor ? orgColor : 'primary', borderColor: orgColor ?? 'primary' }}
              icon={faPlus}
              onClick={() => setShowModalCreate(true)}
            />
          </div>
        }
      >
        {hasFinishedLoading ? (
          <SortableTable
            // TODO: filter out default org?
            data={orgs.filter((o) => o.urlId !== undefined)}
            columns={[
              { label: 'Name', key: 'displayName' },
              { label: 'Link ID', key: 'urlId' }
            ]}
            defaultSortColumn='displayName'
            actions={{
              actions: {
                delete: (id) => {
                  setOrgToDelete(orgs.find((o) => o.fId === id));
                  setShowModalDelete(true);
                },
                view: (id) => navigate(id)
              },
              idKey: 'fId'
            }}
            emptyPlaceholder="No organisations exist yet! Click the '+' button to add one!"
          />
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <Spinner />
          </div>
        )}
      </BlockModule>

      <CreateModal orgs={orgs} isOpen={showModalCreate} setIsOpen={setShowModalCreate} />

      <DeleteModal orgToDelete={orgToDelete} isOpen={showModalDelete} setIsOpen={setShowModalDelete} />
    </>
  );
};

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  orgToDelete: (OrganisationType & WithCreationDate & WithFID) | undefined;
};

const DeleteModal = ({ isOpen, setIsOpen, orgToDelete }: DeleteModalProps) => {
  const [requestError, setRequestError] = React.useState<string>('');
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const globalState = useGlobalState();

  const [userVerified, setUserVerified] = React.useState<boolean>(false);
  const [loadingUserVerification, setLoadingUserVerification] = React.useState<boolean>(false);

  const auth = getAuth();
  const credential = EmailAuthProvider.credential(auth.currentUser?.email!, password);

  const checkUserCredentials = async () => {
    setLoadingUserVerification(true);
    try {
      await reauthenticateWithCredential(auth.currentUser!, credential);
      toast.success(`Successfully verified account: ${auth.currentUser?.email}!`);
      setUserVerified(true);
      setLoadingUserVerification(false);
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        toast.error('Wrong password!');
        setUserVerified(false);
      } else {
        console.log('Error:', error);
      }
      setLoadingUserVerification(false);
    }
  };

  React.useEffect(() => {
    setUserVerified(false);
    setPassword('');
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>Delete Organisation</ModalHeader>
      <ModalBody>
        <div className='bg-light border shadow-sm rounded p-3 mb-4'>
          Organisation name:
          <p>
            <strong>{orgToDelete?.displayName}</strong>
          </p>
          Organisation ID:
          <div>
            <strong>{orgToDelete?.fId}</strong>
          </div>
        </div>
        <Label>You must enter your password and verify your account to perform this action</Label>
        <InputGroup>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder='Enter your password...'
            onChange={(e) => setPassword(e.target.value)}
          ></Input>
          <InputGroupText>
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              color='#212b39'
              onClick={() => setShowPassword(!showPassword)}
            ></FontAwesomeIcon>
          </InputGroupText>
        </InputGroup>
        <Button
          style={{ marginTop: '15px' }}
          color={!userVerified ? 'warning' : 'success'}
          disabled={password.length <= 0}
          onClick={() => checkUserCredentials()}
        >
          {loadingUserVerification && !userVerified ? (
            <Spinner size='sm' />
          ) : userVerified ? (
            <>
              Account verified
              <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faCheck} />
            </>
          ) : (
            'Verify account'
          )}
        </Button>
        <Alert color='danger' className='mt-4 mb-0'>
          This action is{' '}
          <strong>
            <u>permanent</u>, and will delete all data
          </strong>
        </Alert>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!orgToDelete) return;
            setIsLoadingRequest(true);
            setRequestError('');
            try {
              await deleteOrganisation(orgToDelete?.fId, globalState.user?.docId, password);
              setIsOpen(false);
              setIsLoadingRequest(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error adding user: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to add user');
              }
              setIsLoadingRequest(false);
              setUserVerified(false);
            }
          }}
          className='me-auto'
          style={{ minWidth: 185 }}
          disabled={!userVerified || isLoadingRequest}
        >
          {isLoadingRequest ? <Spinner size='sm' /> : 'Delete organisation'}
        </Button>
        <Button color='danger' onClick={() => setIsOpen(false)} disabled={isLoadingRequest}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type CreateModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  orgs: (OrganisationType & WithCreationDate & WithFID)[];
};
const CreateModal = ({ isOpen, setIsOpen, orgs }: CreateModalProps) => {
  const [displayName, setDisplayName] = React.useState<string>('');
  const [orgId, setOrgId] = React.useState<string>('');

  const [idIsUnique, setIdIsUnique] = React.useState<boolean | null>(null);
  const [idIsCorrectlyFormatted, setIdIsCorrectlyFormatted] = React.useState<boolean | null>(null);
  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');

  // TODO: button to generate ID from entered displayname?

  React.useEffect(() => {
    if (isOpen) {
      setDisplayName('');
      setOrgId('');
      setIdIsUnique(null);
      setIdIsCorrectlyFormatted(null);
      setRequestError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader>Create new organisation</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className={styles.label}>Name</Label>
            <Input
              onChange={(e) => setDisplayName(e.target.value)}
              type='text'
              placeholder='Enter a name for the organisation...'
            />
          </FormGroup>

          <FormGroup>
            <Label className={styles.label}>
              URL ID{' '}
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={styles.info}
                title='The URL ID is used when creating public access links to content belonging to an organisation, e.g. as a part of the file download URL.'
              />
            </Label>
            <Input
              onChange={(e) => {
                if (e.target.value.match(/^([-_A-Za-z0-9]){1,50}$/g)) {
                  setIdIsCorrectlyFormatted(true);
                } else {
                  setIdIsCorrectlyFormatted(false);
                }

                if (orgs.some((o) => o.urlId === e.target.value)) {
                  setIdIsUnique(false);
                } else {
                  setIdIsUnique(true);
                  setOrgId(e.target.value);
                }
              }}
              type='text'
              placeholder='Enter a unique link id...'
              invalid={idIsUnique === false || idIsCorrectlyFormatted === false}
            />
            <FormFeedback>
              {!idIsUnique && <p>Organisation's link ID is not unique</p>}
              {!idIsCorrectlyFormatted && <p>Organisation's link ID is incorrectly formatted</p>}
            </FormFeedback>
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '12em' }}
          disabled={
            !idIsUnique ||
            !idIsCorrectlyFormatted ||
            !orgId ||
            !displayName ||
            displayName.length < 1 ||
            formSubmitLoading
          }
          onClick={async () => {
            setFormSubmitLoading(true);
            setRequestError('');
            try {
              await createOrganisation({
                displayName,
                urlId: orgId
              });
              setIsOpen(false);
              setFormSubmitLoading(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error adding user: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to add user');
              }
              setFormSubmitLoading(false);
            }
          }}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : 'Add organisation'}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
