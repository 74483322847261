import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC<{}> = () => {
  return (
    <Container>
      <h2>404: Page not found</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </Container>
  );
};
export default NotFoundPage;
