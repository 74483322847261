import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, FormGroup, Label, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { AuthenticatedUserDataType } from '../../Utils/types';
import styles from './SystemAdminPage.module.scss';
import { BlockModule } from './BlockModule';
import { RoundedIconButton } from '../../Components/Buttons';
import React from 'react';
import { editUser } from '../../Firebase/http';
import axios from 'axios';
import { FirebaseService } from '../../Firebase/Firebase';
import { FirebaseError } from 'firebase/app';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { EmailAuthProvider } from 'firebase/auth';

const firebaseService = FirebaseService;

type UserModuleProps = { user: AuthenticatedUserDataType };

export const UserBlock = (props: UserModuleProps) => {
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);
  const [showModalPassword, setShowModalPassword] = React.useState<boolean>(false);
  const globalState = useGlobalState();
  const organisationColor = globalState.organisation?.organisationColor;

  return (
    <>
      <BlockModule
        title={
          <div className='d-flex mb-2 gap-2'>
            <h2 className='ms-3'>Your Account</h2>
            <RoundedIconButton
              icon={faPen}
              onClick={() => setShowModalEdit(true)}
              tooltip='Edit'
              style={{ backgroundColor: organisationColor ?? 'primary', borderColor: organisationColor ?? 'primary' }}
            />
          </div>
        }
      >
        <div>
          <Form>
            <FormGroup>
              <Label className={styles.label} for='1'>
                Name
              </Label>
              <Input className={styles['input-readonly']} readOnly placeholder={props.user.displayName}></Input>
            </FormGroup>
          </Form>
        </div>
        <Button onClick={() => setShowModalPassword(true)}>Change Password</Button>
      </BlockModule>

      <EditModal isOpen={showModalEdit} setIsOpen={setShowModalEdit} userToModify={props.user} />
      <PasswordModal
        isOpen={showModalPassword}
        setIsOpen={setShowModalPassword}
        userToModify={props.user}
      ></PasswordModal>
    </>
  );
};

type EditModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userToModify: AuthenticatedUserDataType;
};
const EditModal = ({ isOpen, setIsOpen, userToModify }: EditModalProps) => {
  const [displayName, setDisplayName] = React.useState<string>('');
  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');
  const globalState = useGlobalState();

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e: React.MouseEvent) => {
    e.preventDefault();
    setFormSubmitLoading(true);
    setRequestError('');
    try {
      editUser({ displayName: displayName }, userToModify?.credentials.uid);
      globalState.setUser({ ...globalState.user!, displayName: displayName });
      setIsOpen(false);
      setFormSubmitLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setRequestError('Error updating user: ' + error.response?.data);
      } else {
        console.log(error);
        setRequestError('Failed to update user');
      }
      setFormSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setDisplayName(userToModify.displayName!);
    } else {
      setRequestError('');
    }
  }, [isOpen, userToModify.displayName]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader>Update user info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className={styles.label}>Name</Label>
            <Input
              onChange={(e) => setDisplayName(e.target.value)}
              type='text'
              placeholder='Enter a new display name...'
              defaultValue={userToModify?.displayName}
            />
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '12em' }}
          disabled={
            !displayName || displayName.length < 1 || formSubmitLoading || displayName === userToModify?.displayName
          }
          onClick={onSubmit}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : 'Update user'}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type PasswordModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userToModify: AuthenticatedUserDataType;
};
const PasswordModal = ({ isOpen, setIsOpen, userToModify }: PasswordModalProps) => {
  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState<string>('');
  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e: React.MouseEvent) => {
    e.preventDefault();
    setFormSubmitLoading(true);
    setRequestError('');
    try {
      const credential = EmailAuthProvider.credential(userToModify.credentials.email!, currentPassword);
      await firebaseService.reauthenticateWithCredential(userToModify.credentials, credential);
      await firebaseService.updatePassword(userToModify.credentials, newPassword);
      setIsOpen(false);
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/weak-password') {
          setRequestError('Password should be at least 6 characters');
        }
        if (error.code === 'auth/wrong-password') {
          setRequestError('Current password was incorrect');
        }
      } else {
        console.error(error);
      }
    }
    setFormSubmitLoading(false);
  };

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfirm('');
      setRequestError('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader>Change user password</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className={styles.label}>Current password</Label>
            <Input onChange={(e) => setCurrentPassword(e.target.value)} type='password' defaultValue={''} />
          </FormGroup>
          <FormGroup>
            <Label className={styles.label}>New password</Label>
            <Input onChange={(e) => setNewPassword(e.target.value)} type='password' defaultValue={''} />
          </FormGroup>
          <FormGroup>
            <Label className={styles.label}>Repeat password</Label>
            <Input onChange={(e) => setNewPasswordConfirm(e.target.value)} type='password' defaultValue={''} />
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '12em' }}
          disabled={newPassword !== newPasswordConfirm || newPassword.length < 1 || formSubmitLoading}
          onClick={onSubmit}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : 'Change password'}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
