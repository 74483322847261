import LoadingPage from './Components/LoadingPage';
import React from 'react';
import { AppRoutes } from './Routes/AppRoutes';
import { AppShell } from './Components/AppShell';
import { useGlobalState } from './GlobalState/GlobalState';
import { isIOS, isIOS13 } from 'react-device-detect';
import { Navbar, NavbarBrand } from 'reactstrap';
import newFrozenEyeLogo from './Utils/img/newFrozenEyeLogo.png';

const App: React.FC<{}> = () => {
  const globalState = useGlobalState();

  // Disable auto-zooming on input focus on iOS, see https://gitlab.softwerk.se/theFrozenEye/app/-/issues/30 and https://stackoverflow.com/a/57527009
  if (isIOS || isIOS13) {
    const viewportTag = document.querySelector('meta[name=viewport]');

    if (viewportTag) {
      let content = viewportTag.getAttribute('content');
      const regex = /maximum-scale=[0-9.]+/g;

      if (regex.test(content || '')) {
        content = content?.replace(regex, 'maximum-scale=1.0') || '';
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      if (content) {
        viewportTag.setAttribute('content', content);
      }
    }
  }

  return globalState.loading ? (
    <LoadingPage />
  ) : globalState.user ? (
    <AppShell>
      <AppRoutes />
    </AppShell>
  ) : (
    <>
      <Navbar color='primary' dark style={{ height: '3em', padding: 0 }}>
        <NavbarBrand>
          <img src={newFrozenEyeLogo} alt='brand' style={{ height: '2em' }}></img>
          <span>The Frozen Eye</span>
        </NavbarBrand>
      </Navbar>
      <AppRoutes />
    </>
  );
};

export default App;
