import App from './App';
import GlobalState from './GlobalState/GlobalState';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import sv from 'date-fns/locale/sv';
import { registerLocale } from 'react-datepicker';
import { ToastContainer } from 'react-toastify';
import './CustomTheme.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

registerLocale('sv', sv);

const rootElement = document.getElementById('root') as HTMLElement;
rootElement.style.width = '100%';
rootElement.style.height = '100%';

const root = ReactDOM.createRoot(rootElement); // createRoot(container!) if you use TypeScript

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// TODO: <React.StrictMode> removed for the sake of react-google-maps-api
// FIXME: https://github.com/JustFly1984/react-google-maps-api/issues/2978
// https://github.com/JustFly1984/react-google-maps-api/issues/3064
root.render(
  <GlobalState>
    <App />
    <ToastContainer
      position='bottom-center'
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      pauseOnHover
      theme='colored'
    />
  </GlobalState>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
