import axios from 'axios';
import React from 'react';
import { BlockModule } from './BlockModule';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { OrganisationType, WithCreationDate, WithFID } from '../../Utils/types';
import { restoreBackup } from '../../Firebase/http';
import { SortableTable } from '../../Components/SortableTable';

type BackupBlockProps = {
  activeOrg: (OrganisationType & WithCreationDate & WithFID) | undefined;
  backups: string[];
};
export const BackupBlock = ({ activeOrg, backups }: BackupBlockProps) => {
  const [showModalConfirm, setShowModalConfirm] = React.useState<boolean>(false);
  const [dateToRestore, setDateToRestore] = React.useState<string>('');

  return (
    <>
      <BlockModule title='Backups'>
        <div>
          <SortableTable
            data={backups
              .filter((d) => d.localeCompare(activeOrg?.createdAt.toDate().toLocaleDateString('sv-se') || '') > 0) // only show backups that were created AFTER the org was created
              .map((d) => {
                return { date: d, fId: d };
              })}
            columns={[{ label: 'Date', key: 'date' }]}
            defaultSortColumn='date'
            actions={{
              actions: {
                redo: (date) => {
                  setDateToRestore(date);
                  setShowModalConfirm(true);
                }
              },
              idKey: 'date'
            }}
            emptyPlaceholder='No backups have been made yet. Backups are created at 01:00 every night.'
          />
        </div>
      </BlockModule>

      <RestoreBackupModal
        activeOrg={activeOrg}
        dateToRestore={dateToRestore}
        isOpen={showModalConfirm}
        setIsOpen={setShowModalConfirm}
      />
    </>
  );
};

type RestoreBackupModalProps = {
  activeOrg: (OrganisationType & WithCreationDate & WithFID) | undefined;
  dateToRestore: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const RestoreBackupModal = ({ activeOrg, dateToRestore, isOpen, setIsOpen }: RestoreBackupModalProps) => {
  const [requestError, setRequestError] = React.useState<string>('');
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} returnFocusAfterClose={false}>
      <ModalHeader>Restore Backup</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to restore the backup created on <strong>{dateToRestore}</strong>?
        </p>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>
      <ModalFooter className='d-flex'>
        <Button
          color='primary'
          onClick={async () => {
            if (!activeOrg) return;
            setIsLoadingRequest(true);
            setRequestError('');
            try {
              await restoreBackup(activeOrg.fId, dateToRestore);
              setIsOpen(false);
              setIsLoadingRequest(false);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error restoring backup: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to restore backup');
              }
              setIsLoadingRequest(false);
            }
          }}
          className='me-auto'
          style={{ minWidth: 80 }}
          disabled={isLoadingRequest}
        >
          {isLoadingRequest ? <Spinner size='sm' /> : 'Yes'}
        </Button>
        <Button color='danger' onClick={() => setIsOpen(false)} disabled={isLoadingRequest}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
