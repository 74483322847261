import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRef } from 'react';

type RoundedIconButtonProps = {
  className?: string;
  color?: string;
  icon: IconProp;
  onClick?: () => void;
  style?: React.CSSProperties;
  tooltip?: string;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
};
export const RoundedIconButton: React.FunctionComponent<RoundedIconButtonProps> = (props) => {
  const ref = useRef(null);

  return (
    <>
      <span ref={ref}>
        <Button
          color={props.color || 'primary'}
          onClick={props.onClick}
          className={`rounded-circle d-flex align-items-center justify-content-center ${props.className || ''}`}
          style={{ ...{ height: '2.5em', width: '2.5em' }, ...props.style }}
        >
          <FontAwesomeIcon icon={props.icon} />
        </Button>
      </span>
      {props.tooltip != null && props.tooltip.length > 0 ? (
        <UncontrolledTooltip
          placement={props.tooltipPosition ?? 'right'}
          target={ref}
          delay={{ show: 250, hide: 0 }}
          offset={[0, 8]}
        >
          {props.tooltip}
        </UncontrolledTooltip>
      ) : (
        <></>
      )}
    </>
  );
};
