import axios from 'axios';
import React, { ChangeEvent } from 'react';
import styles from './SystemAdminPage.module.scss';
import { BlockModule } from './BlockModule';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import { editOrganisation, uploadImageByOrgId } from '../../Firebase/http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentDataType, OrganisationType, WithCreationDate, WithFID } from '../../Utils/types';
import { RoundedIconButton } from '../../Components/Buttons';
import { faPen, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { GeoPoint, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

type GeneralBlockProps = {
  activeOrg: (OrganisationType & WithCreationDate & WithFID) | undefined;
  orgs: (OrganisationType & WithCreationDate & WithFID)[];
};
export const GeneralBlock = ({ activeOrg, orgs }: GeneralBlockProps) => {
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);
  const globalState = useGlobalState();
  const organisationColor = globalState.organisation?.organisationColor;

  return (
    <>
      <BlockModule
        title={
          <div className='d-flex mb-2 gap-2'>
            <h2 className='ms-3'>General</h2>
            <RoundedIconButton
              style={{
                backgroundColor: organisationColor ? organisationColor : 'primary',
                borderColor: organisationColor
              }}
              icon={faPen}
              onClick={() => setShowModalEdit(true)}
            />
          </div>
        }
      >
        <div>
          <Form>
            <FormGroup>
              <Label className={styles.label} for='1'>
                Name
              </Label>
              <Input className={styles['input-readonly']} readOnly placeholder={activeOrg?.displayName}></Input>
            </FormGroup>
            <FormGroup>
              <Label className={styles.label}>
                URL ID{' '}
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.info}
                  title='The URL ID is used when creating public access links to content belonging to an organisation, e.g. as a part of the file download URL.'
                />
              </Label>
              <Input className={styles['input-readonly']} readOnly placeholder={activeOrg?.urlId}></Input>
            </FormGroup>
            <FormGroup>
              <Label className={styles.label}>Created</Label>
              <p>{activeOrg?.createdAt.toDate().toLocaleDateString('sv-se')}</p>
            </FormGroup>
          </Form>
        </div>
      </BlockModule>

      <EditModal activeOrg={activeOrg} orgs={orgs} isOpen={showModalEdit} setIsOpen={setShowModalEdit} />
    </>
  );
};

type EditModalProps = {
  activeOrg: (OrganisationType & WithCreationDate & WithFID) | undefined;
  isOpen: boolean;
  orgs: (OrganisationType & WithCreationDate & WithFID)[];
  setIsOpen: (isOpen: boolean) => void;
};
const EditModal = ({ activeOrg, isOpen, orgs, setIsOpen }: EditModalProps) => {
  const [displayName, setDisplayName] = React.useState<string>('');
  const [urlId, setUrlId] = React.useState<string>('');

  const [idIsUnique, setIdIsUnique] = React.useState<boolean>(true);
  const [idIsCorrectlyFormatted, setIdIsCorrectlyFormatted] = React.useState<boolean>(true);
  const [formSubmitLoading, setFormSubmitLoading] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>('');

  const [orgColor, setOrgColor] = React.useState<string | undefined>(activeOrg?.organisationColor);
  const [orgColorIsCorrectlyFormatted, setOrgColorIsCorrectlyFormatted] = React.useState<boolean>(true);

  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(activeOrg?.organisationLogo);
  const storage = getStorage();

  const fetchIconFromFirebaseStorage = async (orgId: string, logoName: string) => {
    try {
      const newUrl = await getDownloadURL(ref(storage, `${orgId}/${logoName}`));
      setLogoUrl(newUrl);
    } catch (error) {
      console.error('Error fetching logo URL:', error);
      return '';
    }
  };

  const fetchUserFileName = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0 && activeOrg?.fId! && event.target.files !== null) {
      const uploadedFile = fileInput.files[0];
      const newFileName = Timestamp.now() + uploadedFile.name; //Generates a unique name for the logofile, otherwise returns 403 error if name already exists in storage

      const newFileReader = new FileReader();
      newFileReader.onload = async (e) => {
        const form = new FormData();
        if (e.target?.result) {
          const file = new File([e.target.result], newFileName);
          form.append('file', file);
          form.append(
            'mediaDoc',
            JSON.stringify({
              fileName: newFileName,
              fileType: 'image',
              timestamp: Timestamp.now(),
              location: new GeoPoint(0, 0)
            } as DocumentDataType)
          );
          await uploadImageByOrgId(activeOrg?.fId!, form);
          await fetchIconFromFirebaseStorage(activeOrg?.fId!, newFileName);
        }
      };
      newFileReader.readAsArrayBuffer(event.target.files[0]);
    } else {
      console.log('No file found.');
    }
  };

  // TODO: button to generate ID from entered displayname?

  React.useEffect(() => {
    if (isOpen) {
      setDisplayName(activeOrg?.displayName || '');
      setUrlId(activeOrg?.urlId || '');
      setIdIsUnique(true);
      setIdIsCorrectlyFormatted(true);
      setRequestError('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      returnFocusAfterClose={false}
    >
      <ModalHeader>Update organisation info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className={styles.label}>Name</Label>
            <Input
              onChange={(e) => setDisplayName(e.target.value)}
              type='text'
              placeholder='Enter a name for the organisation...'
              defaultValue={activeOrg?.displayName}
            />
          </FormGroup>

          <FormGroup>
            <Label className={styles.label}>URL ID</Label>
            <Input
              onChange={(e) => {
                if (e.target.value.match(/^([-_A-Za-z0-9]){1,50}$/g)) {
                  setIdIsCorrectlyFormatted(true);
                } else {
                  setIdIsCorrectlyFormatted(false);
                }

                if (orgs.some((o) => o.urlId === e.target.value && o.fId !== activeOrg?.fId)) {
                  setIdIsUnique(false);
                } else {
                  setIdIsUnique(true);
                  setUrlId(e.target.value);
                }
              }}
              type='text'
              placeholder='Enter a unique URL id...'
              invalid={idIsUnique === false || idIsCorrectlyFormatted === false}
              defaultValue={activeOrg?.urlId}
            />
            <FormFeedback>
              {!idIsUnique && <p>Organisation's URL ID is not unique</p>}
              {!idIsCorrectlyFormatted && <p>Organisation's URL ID is incorrectly formatted</p>}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label className={styles.label}>Color</Label> <br />
            Click colorbox to choose color or type in hexcode below
            <div className='d-flex flex-row align-items-center'>
              <input
                defaultValue={activeOrg?.organisationColor}
                type='color'
                onChange={(e) => setOrgColor(e.target.value)}
              />
              <Input
                onChange={(e) => {
                  if (e.target.value.match(/^#(?:(?:[\da-f]{3}){1,2}|(?:[\da-f]{4}){1,2})$/i)) {
                    setOrgColorIsCorrectlyFormatted(true);
                    setOrgColor(e.target.value);
                  } else {
                    setOrgColorIsCorrectlyFormatted(false);
                  }
                }}
                type='text'
                placeholder='Enter a color for the organisation'
                defaultValue={orgColor}
              ></Input>
            </div>
            <FormFeedback>
              {!orgColorIsCorrectlyFormatted && <p>Organisation's color hexcode is not correctly formatted</p>}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label className={styles.label}>Logo</Label>
            <Input type='file' onChange={async (e) => fetchUserFileName(e)}></Input>
          </FormGroup>
        </Form>
        {requestError?.length > 0 && <div className='text-danger'>{requestError}</div>}
      </ModalBody>

      <ModalFooter className='d-flex'>
        <Button
          className='me-auto'
          color='primary'
          style={{ width: '12em' }}
          disabled={
            !idIsUnique ||
            !idIsCorrectlyFormatted ||
            !urlId ||
            !displayName ||
            displayName.length < 1 ||
            !orgColorIsCorrectlyFormatted ||
            !orgColor ||
            formSubmitLoading
          }
          onClick={async () => {
            setFormSubmitLoading(true);
            setRequestError('');
            try {
              await editOrganisation(
                {
                  displayName,
                  urlId: urlId,
                  organisationColor: orgColor,
                  organisationLogo: logoUrl
                },
                activeOrg?.fId || ''
              );
              setIsOpen(false);
              setFormSubmitLoading(false);
              if (activeOrg) {
                activeOrg.urlId = urlId;
                activeOrg.displayName = displayName;
              }
            } catch (error) {
              if (axios.isAxiosError(error)) {
                setRequestError('Error updating organisation: ' + error.response?.data);
              } else {
                console.log(error);
                setRequestError('Failed to update organisation');
              }
              setFormSubmitLoading(false);
            }
          }}
        >
          {formSubmitLoading ? <Spinner size='sm' /> : 'Update organisation'}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={formSubmitLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
