import newFrozenEyeLogo from '../../Utils/img/newFrozenEyeLogo.png';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormGroup, InputGroup, Input, FormFeedback, InputGroupText, Button, Spinner } from 'reactstrap';
import styles from './SignupPage.module.scss';
import React from 'react';
import { useGlobalState } from '../../GlobalState/GlobalState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, verifyPasswordResetCode } from 'firebase/auth';
import { FirebaseService } from '../../Firebase/Firebase';

const NewPasswordPage: React.FC<{}> = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [signupLoading, setSignupLoading] = React.useState<boolean>(false);

  const handleEmailAndPasswordSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    setSignupLoading(true);
    e.preventDefault();
    const FbMode = searchParams.get('mode');
    const FbOobCode = searchParams.get('oobCode');
    const FbApiKey = searchParams.get('apiKey');
    const FbLang = searchParams.get('lang');
    if (FbMode == null || FbOobCode == null || FbApiKey == null || FbLang == null || email == null) {
      console.log('Missing password reset params');
      setSignupLoading(false);
      return;
    }
    try {
      await FirebaseService.confirmPasswordReset(FbOobCode, password);
      setSignupLoading(false);
      navigate('/login', { replace: true });
    } catch (error) {
      console.log('Email and password signup failed:', error);
      setPasswordError(true);
      setSignupLoading(false);
    }
  };

  React.useEffect(() => {
    if (globalState.user) {
      navigate('/', { replace: true });
    }
  }, [globalState.user, navigate]);

  React.useEffect(() => {
    const fetchEmail = async (FbOobCode: string) => {
      const auth = getAuth();
      setEmail(await verifyPasswordResetCode(auth, FbOobCode));
    };

    setSignupLoading(true);
    const FbOobCode = searchParams.get('oobCode');

    if (FbOobCode == null) {
      console.log('Broken oobCode');
      setSignupLoading(false);
      return;
    }

    fetchEmail(FbOobCode);
    setSignupLoading(false);
  }, [searchParams, email]);

  return (
    <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-center ${styles.mainContainer}`}>
      <div
        className={`d-flex flex-column align-items-center justify-content-around rounded p-sm-5 p-xs-0 m-3 ${styles.innerContainer}`}
      >
        <img src={newFrozenEyeLogo} alt='featureLogo' className='mb-sm-3' />
        {email.length === 0 || email == null ? (
          <p>Reset link is invalid!</p>
        ) : (
          <>
            <p>
              Enter new password for <b>{email}</b>
            </p>

            <div className='px-4 mt-sm-5 w-100' style={{ maxWidth: '30rem' }}>
              <Form onSubmit={handleEmailAndPasswordSignup}>
                <FormGroup inline>
                  <InputGroup>
                    <Input
                      invalid={passwordError}
                      id='password'
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='New Password'
                      className={styles.passwordInput}
                      onChange={(e) => {
                        if (passwordError) setPasswordError(false);
                        setPassword(e.target.value);
                      }}
                      value={password}
                      onFocus={() => setPasswordError(false)}
                    />
                    <InputGroupText className={styles.inputIcon}>
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        color='#145f92'
                        onClick={() => setShowPassword(!showPassword)}
                      ></FontAwesomeIcon>
                    </InputGroupText>
                    <FormFeedback>Password is incorrect</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <div className='d-grid'>
                  <Button color='primary' type='submit' disabled={signupLoading}>
                    {signupLoading ? <Spinner size='sm' /> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewPasswordPage;
