const reactAppEnv = process.env.REACT_APP_ENV;

const ENV_VARIABLES = {
  apiKey:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_API_KEY
      : process.env.REACT_APP_STAGING_FIREBASE_API_KEY,
  //________________
  authDomain:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
      : process.env.REACT_APP_STAGING_FIREBASE_AUTH_DOMAIN,
  //________________
  projectId:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_PROJECT_ID
      : process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
  //________________
  storageBucket:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
      : process.env.REACT_APP_STAGING_FIREBASE_STORAGE_BUCKET,
  //________________
  messagingSenderId:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
      : process.env.REACT_APP_STAGING_FIREBASE_MESSAGING_SENDER_ID,
  //________________
  appId:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_APP_ID
      : process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
  //________________
  measurementId:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
      : process.env.REACT_APP_STAGING_FIREBASE_MEASUREMENT_ID,
  //________________
  userName: reactAppEnv === 'production' ? process.env.REACT_APP_USERNAME : process.env.REACT_APP_STAGING_USERNAME,
  //________________
  googleMapsAPIKey:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      : process.env.REACT_APP_STAGING_GOOGLE_MAPS_API_KEY,
  //________________
  reCAPTCHASiteKey:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_reCAPTCHA_SITE_KEY
      : process.env.REACT_APP_STAGING_reCAPTCHA_SITE_KEY,
  //________________
  reCAPTCHASecretKey:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_reCAPTCHA_SECRET_KEY
      : process.env.REACT_APP_STAGING_reCAPTCHA_SECRET_KEY,
  //________________
  functionsBaseUrl:
    reactAppEnv === 'production'
      ? process.env.REACT_APP_FUNCTIONS_BASE_URL
      : process.env.REACT_APP_STAGING_FUNCTIONS_BASE_URL,
  //________________
  breakpoint: parseInt(process.env.REACT_APP_BREAKPOINT || '750')
};

export default ENV_VARIABLES;
